import { createContext, useEffect, useState } from 'react';
import translations from '../components/language/translations.json';

export const LanguageContext = createContext();

export const LanguageProvider  = ({ children }) => {
    const [language, setLanguage] = useState('');

    useEffect(() => {
      const initializeLanguage = async () => {
        let savedLanguage = localStorage.getItem('language');
        if (!savedLanguage) {
          savedLanguage = 'amharic';
          localStorage.setItem('language', savedLanguage);
        }
        setLanguage(savedLanguage);
      };
  
      initializeLanguage();
    }, []);

    const translate = (key) => {
       // console.log(key);
        return translations[language][key] || key;
      };
    
      const changeLanguage = (newLanguage) => {
        setLanguage(newLanguage);
        if(newLanguage === 'አማርኛ'){
          localStorage.setItem('language', "amharic");
        }else if(newLanguage === 'amharic'){
          localStorage.setItem('language', "amharic");
        }else{
          localStorage.setItem('language', "english");
        }
      };
      if (!language) {
        return null; // Render a loader or placeholder until the language is initialized
      }
  return (
    <LanguageContext.Provider
      value={{ language, translate, changeLanguage }}
    >
      {children}
    </LanguageContext.Provider>
  );
};
import { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../assets/styles/login-signup.css'
import axios from 'axios';
import { loginApiUrl, updateForgotenPasswordApiUrl, updatePasswordApiUrl, userToken } from '../assets/api/common-data';
import { LanguageContext } from '../contexts/language-context';

function ChangeLostPassword() {
    const [verificationCode, setVerificationCode] = useState('');
    const [email, setEmail] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [reenterNewPassword, setReenterNewPassword] = useState('');
    const [error, setError] = useState([]);
    const navigate = useNavigate()
    const { translate } = useContext(LanguageContext);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        if (name === 'verificationCode') {
            setVerificationCode(value);
        } else if (name === 'email') {
            setEmail(value);
        } else if (name === 'newPassword') {
            setNewPassword(value);
        } else if (name === 'reenterNewPassword') {
            setReenterNewPassword(value);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (newPassword !== reenterNewPassword) {
            setError(['Passwords do not match'])
            return
        }
        try {
            const response = await axios.post(updateForgotenPasswordApiUrl,
                { verificationCode, email, newPassword });
            // Reset the form
            setVerificationCode('')
            setNewPassword('');
            setReenterNewPassword('');
            navigate('/login')
        } catch (error) {
            if (error.response) {
                setError([error.response.data.message]);
            } else {
                setError(['someting went wrong']);
            }
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="loginsignup-container">
                <h3>{translate('Verify & Set Password')}</h3>

                <label>{translate('Email')}</label>
                <input
                    type="text"
                    name="email"
                    value={email}
                    onChange={handleInputChange}
                    placeholder={translate('Email')}
                />

                <label>{translate('Verification Code')} </label>
                <input
                    type="text"
                    name="verificationCode"
                    value={verificationCode}
                    onChange={handleInputChange}
                    placeholder={translate('Verification Code')}
                />

                <label> {translate('New Password')} </label>
                <input
                    type="password"
                    name="newPassword"
                    value={newPassword}
                    onChange={handleInputChange}
                    placeholder={translate('New Password')}
                />

                <label>{translate('Confirm New Password')}</label>
                <input
                    type="password"
                    name="reenterNewPassword"
                    value={reenterNewPassword}
                    onChange={handleInputChange}
                    placeholder={translate('Reenter New Password')}
                />

                {error.length > 0 &&
                    <div className='loginsignup-error'>
                        {error.map((errorData) => (
                            <p>{errorData}</p>
                        ))}
                    </div>
                }

                <button type="submit">{translate('Send')}</button>
            </div>
        </form>
    );
}

export default ChangeLostPassword;
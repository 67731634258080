import { createContext, useEffect, useState } from 'react';
const { v4: uuidv4 } = require('uuid');

export const AnonymousUserContext = createContext();

export const AnonymousUserProvider = ({ children }) => {
    const [anonymousUser, setAnonymousUser] = useState('');

    useEffect(() => {

        const initializeLanguage = async () => {
            let anonymousUserProfile = localStorage.getItem('deviceId');
            if (!anonymousUserProfile) {
                const uniqueId = uuidv4();
                localStorage.setItem('deviceId', uniqueId);
                anonymousUserProfile = uniqueId;
            }
            setAnonymousUser(anonymousUserProfile);
        };
      
          initializeLanguage();
          
    }, []);

    return (
        <AnonymousUserContext.Provider
            value={{
                anonymousUser
            }}
        >
            {children}
        </AnonymousUserContext.Provider>
    );
};
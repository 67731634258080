import commentIcon from '../../assets/images/icons/comment.svg';
import { deleteQuestionApiUrl, getMyQuestionsApiUrl, userToken } from '../../assets/api/common-data';
import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import { EditorState, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import acceptIcon from '../../assets/images/icons/accept.svg'
import rejectIcon from '../../assets/images/icons/reject.svg'
import deleteIcon from '../../assets/images/icons/delete.svg'
import updateIcon from '../../assets/images/icons/update.svg'
import underReviewIcon from '../../assets/images/icons/underReview.svg'
import Popup from 'reactjs-popup';
import InfiniteScroll from 'react-infinite-scroll-component';
import UpdateQuestionForm from './update-question';

import '../../assets/styles/question.css';
import { useNavigate } from 'react-router-dom';
import { LanguageContext } from '../../contexts/language-context';
import { AnonymousUserContext } from '../../contexts/anonymous-user-context';

function MyQuestionComponent({ refresh }) {
  const [questions, setQuestions] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [error, setError] = useState();
  const [limit, setLimit] = useState(10);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [questionId, setQuestionId] = useState();
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const navigate = useNavigate()
  const { translate } = useContext(LanguageContext);
  const { anonymousUser } = useContext(AnonymousUserContext);

  const closePopup = () => {
    setPopupOpen(false);
  };
  const openPopup = (id) => {
    setPopupOpen(true);
    setQuestionId(id)
    navigate(`/updatequestion/${id}`)
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    refreshFetch();
  }, [refresh]);

  const refreshFetch = async () => {
    if (refresh) {
      fetchData();
    }
  };

  const fetchData = async () => {
    try {
      let fetchUrl = `${getMyQuestionsApiUrl}?page=${page}&limit=${limit}&deviceId=${anonymousUser}`;
      const response = await axios.get(fetchUrl, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      setQuestions(response.data.questions);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      setError('Failed to fetch items.');
    }
  };

  const deleteQuestion = async (id) => {
    try {
        let fetchUrl = `${deleteQuestionApiUrl}?questionId=${id}&deviceId=${anonymousUser}`;
        const response = await axios.put(fetchUrl, {},
            {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            }
        );
        window.location.reload();
    } catch (error) {
        setError(error.message)
    }
};

  if (questions.length === 0) {
    // Render loading state or skeleton screen while fetching data
    return <div>{translate("You have not posted any questions")}.</div>;
  }

  return (
    <>
      <InfiniteScroll
        dataLength={questions.length}
        next={fetchData}
        hasMore={hasMore}
        loader={<h4>Loading...</h4>}
      >
        <div className=''>
          <h3>{translate("My Questions")}</h3>
          {questions.map((question) => (
            <div key={question.id} className='question-wrapper'>

                <div className='question-status'>
                  {question.isPassed === true &&
                    <img src={acceptIcon} alt='view' />}
                  {question.isPassed === false &&
                    <img src={rejectIcon} alt='view' />}
                  {question.isPassed === null &&
                    <img src={underReviewIcon} alt='view' />}
                </div>

              <a href={`/question/${question.id}`}>
                <div>
                  <Editor
                    editorState={EditorState.createWithContent(convertFromRaw(JSON.parse(question.question)))}
                    toolbarHidden
                    readOnly
                    wrapperClassName='wrapper-class'
                    editorClassName='editor-class-question-view' />
                </div>
              </a>
              <div className='single-question-status'>
                <div className='single-question-status-data'>
                  <img src={commentIcon} alt='view' />
                  <p>{question.answerCount}</p>
                </div>

                <div className='single-question-status-data' onClick={() => openPopup(question.id)}>
                  <img src={updateIcon} alt='view' />
                </div>

                <div className='single-question-status-data' onClick={() => deleteQuestion(question.id)}>
                  <img src={deleteIcon} alt='view' />
                </div>
              </div>
              <Popup
                open={isPopupOpen}
                onClose={closePopup}
                modal
                centered
              >
                <div className='popup-content'>
                  <button className='popup-close-button' onClick={closePopup}>
                    &times;
                  </button>
                  <UpdateQuestionForm questionId={questionId} />
                </div>
              </Popup>
            </div>
          ))}
        </div>
      </InfiniteScroll>


    </>
  );
}

export default MyQuestionComponent;
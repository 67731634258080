import NavBar from "../components/navbar/navbar";
import "../assets/styles/home.css"
import Footer from "../components/footer/footer";
import MyAnswerComponent from "../components/answer/my-answers";

function MyAnswer() {
    return (
        <>
            <NavBar />
            <div className='home-wrapper'>
                <div className='col-xl-1 '>
                </div>
                <div className="col-xl-6 ">
                    <div className=' '>
                        <MyAnswerComponent />
                    </div>
                    <div className=' '>
                        
                    </div>

                </div>
                <div className="col-xl-3">
                    <div className=' '>
                    </div>
                </div>
                <div className="col-xl-2">

                </div>
            </div>
            <Footer/>
        </>
    )
}

export default MyAnswer;
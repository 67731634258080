import logo from '../../assets/images/logo/logo.svg'
import '../../assets/styles/logo.css'

function Logo() {
    return (
        <div className='logo-wrapper'>
            <a href='/'>
                <img src={logo} alt="" />
            </a>

        </div>

    )
}
export default Logo;  
import '../../assets/styles/notification.css'
function NotificationComponent() {
    return (

        <div className='notifications-wrapper'>
            <h5>Notification</h5>
            <div className='single-notification'>
            </div>

        </div>
    );
}

export default NotificationComponent;
import { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../assets/styles/login-signup.css'
import axios from 'axios';
import { forgotPasswordApiUrl, loginApiUrl } from '../assets/api/common-data';
import { LanguageContext } from '../contexts/language-context';

function ForgotPassword() {

    const [email, setEmail] = useState('');
    const [error, setError] = useState([]);
    const navigate = useNavigate()
    const { translate } = useContext(LanguageContext);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        if (name === 'email') {
            setEmail(value);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await axios.post(forgotPasswordApiUrl, { email });
            // Reset the form
            setEmail('');
            navigate('/changelostpassword')
        } catch (error) {
            if (error.response) {
                setError([error.response.data.message]);
            } else {
                setError(['someting went wrong']);
            }
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="loginsignup-container">
                <h3>{translate("Forgot Password")} </h3>
                <label className='forgot-password-label'>{translate(`We'll send a verification code to the address linked to your account.`)} </label>

                <label>{translate("Email")}</label>
                <input
                    type="text"
                    name="email"
                    value={email}
                    onChange={handleInputChange}
                    placeholder={translate("Email")}
                />
                {error.length > 0 &&
                    <div className='loginsignup-error'>
                        {error.map((errorData) => (
                            <p>{errorData}</p>
                        ))}
                    </div>
                }
                <button type="submit">{translate("Send")}</button>
            </div>
        </form>
    );
}

export default ForgotPassword;
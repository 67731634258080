import NavBar from "../components/navbar/navbar";
import Question from "../components/question/questions";
import "../assets/styles/home.css"
import Footer from "../components/footer/footer";

function Home() {
    return (
        <>
            <NavBar />
            <div className='home-wrapper'>
                <div className='col-xl-1 col-md-1 '>
                </div>
                <div className="col-sm-12  col-md-8 col-xl-6 ">
                    <div className=' '>
                        <Question />
                    </div>
                    <div className=' '>
                        
                    </div>

                </div>
                <div className="col-sm-12 col-xl-3">
                    <div className=' '>
                    </div>
                </div>
                <div className="col-xl-2">

                </div>
            </div>

            <Footer/>

        </>
    )
}

export default Home;
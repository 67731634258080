import { useContext } from 'react';
import '../../assets/styles/footer-component.css'
import { LanguageContext } from '../../contexts/language-context';
function AboutWarkaComponent() {
    const { language } = useContext(LanguageContext);
    console.log(language)

    return (

        <div className='footer-component-wrapper'>
            {language === "amharic" ? (
                <div className='footer-component-amharic'>
                    <h1>ስለ ዋርካስር</h1>

                    <p>ዋርካስር የጥያቄ እና መልስ ድህረ ገጽ ሲሆን ተጠቃሚዎች ጥያቄ የሚጠይቁበት እና ከህብረተሰቡ መልስ የሚያገኙበት መድረክ ነው። ሁለቱንም የአማርኛ እና የእንግሊዘኛ ቋንቋዎችን ይደግፋል፣ ይህም ተጠቃሚዎች በመረጡት ቋንቋ እንዲገናኙ እና እውቀት እንዲያካፍሉ ያስችላቸዋል።</p>
                    <p>ዋርካስር ላይ ተጠቃሚዎች አካውንት ፈጥረው ፕሮፋይሎቻቸውን በመረጃ ማበጀት ይችላሉ። በተለያዩ ርዕሰ ጉዳዮች ላይ ጥያቄዎችን መጠየቅ እና ያሉትን ጥያቄዎች እና መልሶች ማሰስ ይችላሉ። መድረኩ ተጠቃሚዎች በሌሎች ለሚነሱ ጥያቄዎች አጋዥ እና መረጃ ሰጪ መልሶች እንዲሰጡ ያበረታታል።</p>
                    <p>የድረ-ገጹ የተጠቃሚ በይነገጽ ለተጠቃሚ ምቹ እና አስተዋይ እንዲሆን የተቀየሰ ሲሆን ተጠቃሚዎች በተለያዩ ክፍሎች እና ባህሪያት በቀላሉ እንዲሄዱ ያስችላቸዋል። ተጠቃሚዎች የተወሰኑ ጥያቄዎችን ወይም የሚስቡ ርዕሶችን መፈለግ፣ ስለ አዳዲስ መልሶች ማሳወቂያዎችን ለመቀበል ጥያቄዎችን መከተል እና በጥራት እና ተገቢነት ላይ በመመስረት ምላሾችን መደገፍ ወይም ማቃለል ይችላሉ።</p>
                    <p>የመረጃ ጥራት እና አስተማማኝነት ለማረጋገጥ ዋርካስር ልከኝነት እና የማህበረሰብ መመሪያዎችን ተግባራዊ ያደርጋል። ተጠቃሚዎች ተገቢ ያልሆነ ይዘትን ወይም ባህሪን ሪፖርት ማድረግ ይችላሉ፣ እና አወያዮች የተከበረ እና አካታች አካባቢን ለመጠበቅ ሪፖርት የተደረገባቸውን ይዘቶች ይገመግማሉ።</p>
                    <p>ዋርካ አላማው ንቁ የሆነ የእውቀት መጋራት ማህበረሰብን ማፍራት ሲሆን ተጠቃሚዎች ከሌሎች ጋር የሚገናኙበት፣ በተለያዩ ጉዳዮች ላይ ያላቸውን ግንዛቤ ለማስፋት እና ለመድረኩ የጋራ ጥበብ አስተዋፅዖ ያደርጋሉ።</p>
                </div>

            ) : (
                <div className='footer-component-english'>
                    <h1>About Warkaser</h1>

                    <p>Warka is a question-and-answer website, offering a platform for users to ask questions and receive answers from the community. It supports both the Amharic and English languages, allowing users to interact and share knowledge in their preferred language.</p>

                    <p>On Warka, users can create an account and personalize their profiles with information about themselves. They can ask questions on various topics and browse through existing questions and answers. The platform encourages users to provide helpful and informative answers to questions posed by others.</p>

                    <p>The website's user interface is designed to be user-friendly and intuitive, enabling users to easily navigate through different sections and features. Users can search for specific questions or topics of interest, follow questions to receive notifications about new answers, and upvote or downvote answers based on their quality and relevance.</p>

                    <p>To ensure the quality and reliability of information, Warka implements moderation and community guidelines. Users can report inappropriate content or behavior, and moderators review reported content to maintain a respectful and inclusive environment.</p>

                    <p>Warka aims to foster a vibrant community of knowledge-sharing, where users can connect with others, expand their understanding of various subjects, and contribute to the collective wisdom of the platform.</p>
                </div>
            )}
        </div>

    )
}
export default AboutWarkaComponent;  
import commentIcon from '../../assets/images/icons/comment.svg';
import { apiUrlForProfilePicture, getQuestionsForAllApiUrl, getQuestionsForAllBySearchApiUrl, userToken } from '../../assets/api/common-data';
import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import { EditorState, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import userIcon from '../../assets/images/icons/user-icon.svg'
import viewIcon from '../../assets/images/icons/view.svg'
import likeIcon from '../../assets/images/icons/like.svg'
import disLikeIcon from '../../assets/images/icons/dislike.svg'
import '../../assets/styles/question.css';
import '../../assets/styles/answer.css'
import Moment from 'react-moment';
import InfiniteScroll from 'react-infinite-scroll-component';
import htmlReactParser from 'html-react-parser';
import { LanguageContext } from '../../contexts/language-context';
import { SearchContext } from '../../contexts/search-context';
import { CategoriesContext } from '../../contexts/category-context';

function SearchQuestion({ refresh }) {
    const [questions, setQuestions] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [error, setError] = useState();
    const [limit, setLimit] = useState(2);
    const [answers, setAnswers] = useState([]);
    const [textFromRichText, setTextFromRichText] = useState({});
    const [characterCount, setCharacterCount] = useState({});
    const [expandedAnswerId, setExpandedAnswerId] = useState(null);
    const [showFullText, setShowFullText] = useState(false);
    const [hasMore, setHasMore] = useState(false);
    const [page, setPage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const { translate ,language} = useContext(LanguageContext);
    const { searchQuery, search, setSearch, setSearchQuery, handleSearch } = useContext(SearchContext)
    const { selectedCategory, refreshQuestions, changeRefreshQuestions } = useContext(CategoriesContext);

    useEffect(() => {
        let savedLanguage = localStorage.getItem('language');
        if (!savedLanguage) {
            localStorage.setItem('language', 'amharic');
        }
        let storedCategory = localStorage.getItem('selected-category');
        if (!storedCategory) {
            localStorage.setItem('selected-category', 'all');
        }
        if (search === true) {
                fetchData();
        }

    }, [search,selectedCategory]);

    const fetchData = async () => {
        try {
            let fetchUrl = `${getQuestionsForAllBySearchApiUrl}?searchQuery=${searchQuery}&selectedCategory=${selectedCategory}`;
            const response = await axios.get(fetchUrl);
            const uniqueQuestions = response.data.questions.filter(
                (newQuestions) => !answers.some((existingQuestion) => existingQuestion.id === newQuestions.id)
            );
            setQuestions(response.data.questions);
            setPage(response.data.currentPage + 1);
            setTotalCount(response.data.totalCount);
            setTotalPages(response.data.totalPages)
            setSearch(false)
            changeRefreshQuestions(false)

            if (response.data.currentPage >= response.data.totalPages) {
                setHasMore(false);
            } else {
                setHasMore(true)
            }
        } catch (error) {
            setError('Failed to fetch items.');
        }
    };

    if (questions.length === 0) {
        if (language === "amharic") {
          return <div>በ {translate(selectedCategory)}  ምድብ ውስጥ ለፍለጋ ቃል ምንም ጥያቄዎች አልተገኙም።</div>;
        } else {
            return <div>No questions were found for the search term in the {selectedCategory} category.</div>
        }
      }


    return (
        <InfiniteScroll
            dataLength={questions.length}
            next={fetchData}
            hasMore={hasMore}
            loader={<h4>Loading...</h4>}
        >
            <div>
                {questions.map((question) => (
                    <div key={question.id} className='question-wrapper'>
                        <a href={`/question/${question.id}`}>
                            <div>
                                <Editor
                                    editorState={EditorState.createWithContent(convertFromRaw(JSON.parse(question.question)))}
                                    toolbarHidden
                                    readOnly
                                    wrapperClassName='wrapper-class'
                                    editorClassName='editor-class-question-view'
                                />
                            </div>
                        </a>
                        <div className='single-question-status'>
                            <div className='single-question-status-data'>
                                <img src={commentIcon} alt='view' />
                                <p>{question.answerCount}</p>
                            </div>
                        </div>

                    </div>
                ))}
            </div>
        </InfiniteScroll>
    );
}

export default SearchQuestion;
import profile from '../../assets/images/default/default-profile-picture.jpg'
import refresh from '../../assets/images/icons/refresh.svg'
import { useNavigate } from 'react-router-dom'
import { useContext, useEffect, useState } from 'react'
import { UserContext } from '../../contexts/user-context'
import { apiUrlForProfilePicture } from '../../assets/api/common-data'

import '../../assets/styles/user-profile.css'
import { LanguageContext } from '../../contexts/language-context'


function UserProfile() {
    const { isLoggedIn, user, } = useContext(UserContext);
    const { translate } = useContext(LanguageContext);

    const navigate = useNavigate()


    const handleVerifyClick = () => {
        navigate("/verify")
    };

    const handleUpdateProfileClick = () => {
        navigate("/updateprofile")
    };

    const handleMyAnswerClick = () => {
        navigate("/myanswers")
    };

    const handleMyQuestionClick = () => {
        navigate("/myquestion")
    };
    const handleUpdateProfilePictureClick = () => {
        navigate("/updateprofilepicture")
    };

    const handleLogoutClick = () => {
        localStorage.clear();
        navigate("/")
        window.location.reload();
    };


    return (
        <>
            <div className='user-profile'>
                <div className='user-profile-image-and-name '>
                    <div className='user-profile-image-wrapper'>
                        <div className="user-profile-image">
                            {user.profilePicture ? (
                                <img src={apiUrlForProfilePicture + user.profilePicture} alt="" />
                            ) : (
                                <img src={profile} alt="" />
                            )}
                        </div>
                        <div className='user-profile-change-image'>
                            <img src={refresh} alt="" onClick={handleUpdateProfilePictureClick} />
                        </div>
                    </div>
                    <div className='user-profile-name'>
                        <div>
                            <p>{user.userName}</p>
                            <p>{user.email}</p>
                            <p>{translate(`${user.gender}`)}</p>
                        </div>
                        <div className='user-profile-button'>
                            <button onClick={() => handleUpdateProfileClick()}>{translate("Update Profile")}</button>
                            <button onClick={() => handleLogoutClick()}>{translate("Logout")}</button>
                        </div>
                    </div>
                </div>
                {!user.isVerified && (
                    <div className='user-profile-notice'>
                        <img src={"noticeIcon"} alt="" />
                        <p>{translate("Your email is unverified; if another user takes this email, they can use it and have ownership of questions and answers.")}</p>
                        <button onClick={() => handleVerifyClick()}>{translate("Verify")}</button>
                    </div>
                )}

                <div className='user-profile-question-answer' onClick={() => handleMyQuestionClick()}>
                    <p>{translate("My Questions")}</p>
                </div>
                <div className='user-profile-question-answer' onClick={() => handleMyAnswerClick()}>
                    <p>{translate("My Answers")}</p>
                </div>
            </div>
        </>
    );
}

export default UserProfile;
import { createContext, useEffect, useState } from 'react';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState('');


  useEffect(() => {
    const userProfile = localStorage.getItem('userProfile');
  //  const userProfile = JSON.parse(localStorage.getItem('userProfile'))

    if (userProfile) {
      setIsLoggedIn(true);
      const parsedUserProfile = JSON.parse(userProfile);
      setUser(parsedUserProfile?.user);
    }
  }, []);

  return (
    <UserContext.Provider
      value={{
        isLoggedIn,
        user
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
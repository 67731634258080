import { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../assets/styles/login-signup.css'
import axios from 'axios';
import { loginApiUrl } from '../assets/api/common-data.js';
import { LanguageContext } from '../contexts/language-context.js';

function Login() {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState([]);
    const navigate = useNavigate()
    const { translate } = useContext(LanguageContext);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        if (name === 'email') {
            setEmail(value);
        } else if (name === 'password') {
            setPassword(value);
        }
    };

    const handleMyQuestionClick = () => {
        navigate("/myquestion")
    };
    const handleMyAnswerClick = () => {
        navigate("/myanswers")
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await axios.post(loginApiUrl, { email, password });
            localStorage.setItem('userProfile', JSON.stringify(response.data));
            // Reset the form
            setEmail('');
            setPassword('');
            navigate('/')
            window.location.reload();

        } catch (error) {
            if (error.response) {
                setError([error.response.data.message]);
            } else {
                setError(['someting went wrong']);
            }


        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="loginsignup-container">


                <div className='user-profile-question-answer' onClick={() => handleMyQuestionClick()}>
                    <p>{translate("My Questions")}</p>
                </div>
                <div className='user-profile-question-answer' onClick={() => handleMyAnswerClick()}>
                    <p>{translate("My Answers")}</p>
                </div>
                <div className='user-profile-question-answer'>
                </div>

                <h3>{translate("Login")} </h3>
                <label>{translate("Email")} </label>
                <input
                    type="email"
                    name="email"
                    value={email}
                    onChange={handleInputChange}
                    placeholder={translate("Email")}
                />
                <label>{translate("Password")} </label>
                <input
                    type="password"
                    name="password"
                    value={password}
                    onChange={handleInputChange}
                    placeholder={translate("Password")}
                />
                <a href='/forgotpassword' className='loginsignup-forgot-password'>{translate("Forgot password")}?</a>
                {error.length > 0 &&
                    <div className='loginsignup-error'>
                        {error.map((errorData) => (
                            <p>{errorData}</p>
                        ))}
                    </div>
                }
                <button type="submit">{translate("Login")} </button>
                <p className="loginsignup-login">
                    {translate("Not a member yet")}?{' '}
                    <span>
                        <Link to="/signup" className="loginsignup-link">
                            {translate("Signup here")}
                        </Link>
                    </span>
                </p>
            </div>
        </form>
    );
}

export default Login;
import { useContext } from 'react';
import notification from '../../assets/images/icons/notification.svg'
import '../../assets/styles/notification.css'
import { UserContext } from '../../contexts/user-context';
import { LanguageContext } from '../../contexts/language-context';
import { useNavigate } from 'react-router-dom';

function Notification() {
    const { isLoggedIn } = useContext(UserContext);
    const { translate } = useContext(LanguageContext);
    const navigate = useNavigate()

    const handleUserClick = () => {
        navigate('/notifications')
    };

    /* return (
 
 
         <div className="notifcation" onClick={handleUserClick}>
             <div className="notifcation-icon" ><img src={notification} alt="" />
             </div>
             <p className="notifcation-name">{10}</p>
         </div>
 
     )*/

    if (isLoggedIn) {
        return (

            <div className="notifcation" onClick={handleUserClick}>
                <div className="notifcation-icon" ><img src={notification} alt="" />
                </div>
                <p className="notifcation-name">{0}</p>
            </div>
        );
    }














}
export default Notification;  
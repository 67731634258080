import { useContext, useState } from 'react';
import defaultProfile from '../assets/images/default/default-profile-picture.jpg';

import { UserContext } from '../contexts/user-context';
import { useNavigate } from 'react-router-dom';
import { apiUrlForProfilePicture, updateUserProfilePictureApiUrl, userToken } from '../assets/api/common-data.js';
import axios from 'axios';
import Compressor from 'compressorjs';
import { LanguageContext } from '../contexts/language-context.js';

function UpdateProfilePicture() {
    const { isLoggedIn, user } = useContext(UserContext);
    const { translate } = useContext(LanguageContext);

    const [newProfilePicture, setNewProfilePicture] = useState(null);
    const [compressedProfilePicture, setCompressedProfilePicture] = useState(null);

    const [imageSwitch, setImageSwitch] = useState(false);
    const [error, setError] = useState('');

    const navigate = useNavigate();

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        setNewProfilePicture(file);

        try {
            const uniqueFilename = generateUniqueFilename(file.name);
            const options = {
                maxWidth: 300, // Set the maximum width (optional)
                maxHeight: 300, // Set the maximum height (optional)
                quality: 0.8, // Set the image quality between 0 and 1 (optional)
                success(result) {
                    const compressedFile = new File([result], uniqueFilename, {
                        type: file.type,
                        lastModified: Date.now(),
                    });
                    setCompressedProfilePicture(compressedFile);
                },
                error(error) {
                    console.error('Error compressing image:', error);
                },
            };

            new Compressor(file, options);
        } catch (error) {
            console.error('Error compressing image:', error);
        }
        setImageSwitch(true);
    };
    const generateUniqueFilename = (filename) => {
        const timestamp = Date.now();
        const extension = filename.split('.').pop();
        const basename = filename.replace(/\.[^/.]+$/, '');
        return `${user.id}_${basename}_${timestamp}.${extension}`;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const formData = new FormData();
            if (compressedProfilePicture) {
                formData.append('profilePicture', compressedProfilePicture);
            }
            const response = await axios.put(updateUserProfilePictureApiUrl, formData,
                {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                }
            );
            localStorage.setItem('userProfile', JSON.stringify(response.data));
            navigate('/');
            window.location.reload();
        } catch (error) {
            if (error.response) {
                const { data } = error.response;
                setError(data.message);
            } else {
                setError('Something went wrong');
            }
        }


    };

    return (
        <>
            <form onSubmit={handleSubmit} encType="multipart/form-data">
                <div className="user-profilepicture">
                    <div >
                        {imageSwitch === false && (
                            <>
                                {user.profilePicture && (
                                    <div className="selected-profilepicture">
                                        <img src={apiUrlForProfilePicture + user.profilePicture} alt="" />
                                    </div>
                                )}
                                {!user.profilePicture && (
                                    <div className="selected-profilepicture">
                                        <img src={defaultProfile} alt="" />
                                    </div>
                                )}
                            </>
                        )}

                        {imageSwitch === true && (
                            <div className="selected-profilepicture">
                                <img src={URL.createObjectURL(newProfilePicture)} alt="" />
                            </div>
                        )}
                    </div>

                    <div className="select-profilepicture">
                        <input type="file" id="images" onChange={handleImageChange} />
                    </div>

                    <div className="update-profilepicture-button">
                        <button type="submit">Update</button>
                    </div>
                </div>
            </form>
        </>
    );
}

export default UpdateProfilePicture;
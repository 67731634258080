import React, { useContext, useEffect, useState } from 'react';
import searchIcon from '../../assets/images/icons/search.svg';
import { SearchContext } from '../../contexts/search-context';
import '../../assets/styles/search.css';
import { useNavigate } from 'react-router-dom';
import { CategoriesContext } from '../../contexts/category-context';
import { LanguageContext } from '../../contexts/language-context';

function Search() {
  const [searchOption, setSearchOption] = useState('all');
  const navigate = useNavigate();
  const { searchQuery, setSearchQuery, handleSearch } = useContext(SearchContext);
  const { categories,refreshQuestions,changeRefreshQuestions } = useContext(CategoriesContext);
  const [selectedCategory, setSelectedCategory] = useState();
  const { translate } = useContext(LanguageContext);

  useEffect(() => {
    const storedCategory = localStorage.getItem('selected-category');
    setSelectedCategory(storedCategory);
    setSearchOption(storedCategory || 'all');
  }, []);

  const handleChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleOptionChange = (event) => {
    const selectedValue = event.target.value;
    setSearchOption(selectedValue);
    localStorage.setItem('selected-category', selectedValue);
    changeRefreshQuestions(true)
   // window.location.reload()
  };

  const handleClick = () => {
    navigate("/search");
    handleSearch();
  };

  return (
    <>
      <div className="search">
        <select value={searchOption} onChange={handleOptionChange}>
          <option value="all">{translate("All")}</option>
          {categories.map((category) => (
            <option key={category.id} value={category.category}>
             {/* {category.category}*/}
              {translate(category.category)}
            </option>
          ))}
        </select>
        <input
          id="search-input"
          type="text"
          className="search-bar"
          placeholder={translate("Search")}
          value={searchQuery}
          onChange={handleChange}
        />
        <div className="search-icon">
          <img src={searchIcon} alt="search" onClick={handleClick} />
        </div>
      </div>
    </>
  );
}

export default Search;
import '../assets/styles/login-signup.css'
import { useContext, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { signupApiUrl } from '../assets/api/common-data';
import { LanguageContext } from '../contexts/language-context';

function Signup() {
    const [userName, setUserName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [reenterPassword, setReenterPassword] = useState('');
    const [gender, setGender] = useState('');
    const [error, setError] = useState([]);
    const navigate = useNavigate();
    const { translate } = useContext(LanguageContext);

    const validateUserName = () => {
        if (password.length < 0) {
            setError(['Username should be at least 1 characters long.']);
            return;
        }
    }
    const validateEmail = () => {
        let transformedEmail = email;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (emailRegex.test(transformedEmail)) {
            setError('');
        } else {
            setError(['Please enter a valid email address.']);
            return
        }
    };

    const validatePassword = () => {
        if (password.length < 4) {
            setError(['Password should be at least 4 characters long.']);
            return;
        }
    }


    const handleInputChange = (event) => {
        const { name, value } = event.target;
        if (name === 'userName') {
            setUserName(value);
        } else if (name === 'email') {
            setEmail(value);
        } else if (name === 'password') {
            setPassword(value);
        } else if (name === 'reenterPassword') {
            setReenterPassword(value);
        } else if (name === 'gender') {
            setGender(value);
        }
    };
    const handleGenderChange = (event) => {
        setGender(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (password !== reenterPassword) {
            setError(['Passwords do not match.']);
            return;
        }
        if (gender === '') {
            setError(['Please select a gender.']);
            return;
        }
        if (email === '') {
            setError(['Please enter a valid email address.']);
            return;
        }

        try {
            const response = await axios.post(signupApiUrl, { userName, email, password, gender });
            console.log(response.data);
            localStorage.setItem("userProfile", JSON.stringify(response.data));

            // Reset the form
            setUserName('');
            setEmail('');
            setPassword('');
            setReenterPassword('')
            setGender('')
            navigate('/verify')
            window.location.reload();

        } catch (error) {
            if (error.response) {
                setError(error.response.data.message);
            } else {
                setError(['someting went wrong'])
            }
        }

    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="loginsignup-container ">
                <h3>{translate("Signup")}</h3>
                <label>{translate("Username")} </label>
                <input type="text" name="userName" value={userName} onChange={handleInputChange}
                    onBlur={validateUserName} placeholder={translate("Username")} />
                <label>{translate("Email")} </label>
                <input type="email" name="email" value={email} onChange={handleInputChange}
                    onBlur={validateEmail} placeholder={translate("Email")} />
                <label>{translate("Password")} </label>
                <input type="password" name="password" value={password}
                    onChange={handleInputChange} onBlur={validatePassword} placeholder={translate("Password")} />

                <label>{translate("Reenter Password")} </label>
                <input type="password" name="reenterPassword" value={reenterPassword}
                    onChange={handleInputChange} placeholder={translate("Reenter Password")} />

                <label>{translate("Gender")} </label>
                <select name="gender" value={gender} onChange={handleGenderChange}>
                    <option value="">{translate("Select Gender")} </option>
                    <option value="MALE">{translate("MALE")} </option>
                    <option value="FEMALE">{translate("FEMALE")} </option>
                </select>
                {error.length > 0 &&
                    <div className='loginsignup-error'>
                        {error.map((errorData) => (
                            <p>{errorData}</p>
                        ))}
                    </div>
                }
                <button type='submit'>{translate("Signup")} </button>
                <p className="loginsignup-login">{translate(`By continuing, i agree to the terms of use & privacy policy. Do you have already an account`)}?
                    <span>
                        <Link to='/login' className='loginsignup-link'>
                            {translate("Login here")}
                        </Link>
                    </span>
                </p>
            </div>
        </form>
    );
};

export default Signup;
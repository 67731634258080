import { useContext, useEffect, useState } from 'react';
import { EditorState, convertToRaw, Modifier, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import '../../assets/styles/question-answer-form.css'
import { createQuestionApiUrl, singleQuestionApiUrl, updateQuestionApiUrl, userToken } from '../../assets/api/common-data';
import axios from 'axios';
import { UserContext } from '../../contexts/user-context';
import MyQuestion from './my-question';
import acceptIcon from '../../assets/images/icons/accept.svg'
import rejectIcon from '../../assets/images/icons/reject.svg'
import deleteIcon from '../../assets/images/icons/delete.svg'
import updateIcon from '../../assets/images/icons/update.svg'
import underReviewIcon from '../../assets/images/icons/underReview.svg'
import { LanguageContext } from '../../contexts/language-context';
import { AnonymousUserContext } from '../../contexts/anonymous-user-context';

function UpdateQuestionForm({ questionId }) {
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
    const [refresh, setRefresh] = useState(false);
    const [error, setError] = useState('');
    const [isEmpty, setIsEmpty] = useState(false);
    const [questions, setQuestions] = useState('');
    const { user } = useContext(UserContext);
    const { translate } = useContext(LanguageContext);
    const { anonymousUser } = useContext(AnonymousUserContext);

    const handleEditorChange = (newEditorState) => {
        setEditorState(newEditorState);
    };
    useEffect(() => {
        fetchData();
    }, []);

    const handlePastedText = (text, html, editorState) => {
        // Extract the font size from the HTML
        const fontSizeRegex = /font-size:\s*(\d+)px;/g;
        const fontSizeMatches = [...html.matchAll(fontSizeRegex)];
        const fontSize = fontSizeMatches.length > 0 ? fontSizeMatches[0][1] : null;

        // Apply the new inline style to the pasted content
        const contentState = editorState.getCurrentContent();
        const selectionState = editorState.getSelection();

        let newContentState = Modifier.replaceText(
            contentState,
            selectionState,
            text
        );

        if (fontSize) {
            newContentState = Modifier.applyInlineStyle(
                newContentState,
                selectionState,
                `FONT_SIZE_${fontSize}`
            );
        }

        // Update the editor state
        const newEditorState = EditorState.push(
            editorState,
            newContentState,
            'insert-characters'
        );
        setEditorState(newEditorState);

        return 'handled';
    };

    const fetchData = async () => {
        try {
            const contentState = editorState.getCurrentContent();
            const contentIsEmpty = !contentState.hasText();
            if (contentIsEmpty) {
                setIsEmpty(true)
            }
            const questionContent = JSON.stringify(convertToRaw(editorState.getCurrentContent()));

            let fetchUrl = `${singleQuestionApiUrl}?questionId=${questionId}&deviceId=${anonymousUser}`;
            const response = await axios.get(fetchUrl, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            }
            );
            setEditorState(EditorState.createWithContent(convertFromRaw(JSON.parse(response.data.questions.question)))); setError('')
            setIsEmpty(false)
            setQuestions(response.data.questions)

        } catch (error) {
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const contentState = editorState.getCurrentContent();
            const contentIsEmpty = !contentState.hasText();
            if (contentIsEmpty) {
                setError('Empty question. Please enter some content.');
                setEditorState('')
                setRefresh(false)
            } else {
                try {
                    const questionContent = JSON.stringify(convertToRaw(editorState.getCurrentContent()));
                    const response = await axios.put(updateQuestionApiUrl,
                         { questionId: questionId, question: questionContent,deviceId:anonymousUser },
                        {
                            headers: {
                                Authorization: `Bearer ${userToken}`,
                            },
                        }
                    );
                    setEditorState('')
                    setRefresh(true)
                    setError('')
                    window.location.reload();
                } catch (error) {
                    if (error.response) {
                        const { data } = error.response;
                        setError(data.message)
                    } else if (error.request) {
                        setError('Something went wrong');
                    } else {
                        setError('Something went wrong');
                    }
                }
            }
        } catch (error) {
            setError('Empty question. Please enter some content.');

        }

    }


    return (
        <>
            <form onSubmit={handleSubmit}>

                <div className='question-answer-form-wrapper'>
                    <div className='question-status-wrapper'>

                        <div className='question-status'>
                            {questions.isPassed === true &&
                                <img src={acceptIcon} alt='view' />}
                            {questions.isPassed === false &&
                                <img src={rejectIcon} alt='view' />}
                            {questions.isPassed === null &&
                                <img src={underReviewIcon} alt='view' />}
                        </div>


                        <div className='question-status'>
                            <img src={deleteIcon} alt='view' />
                        </div>
                    </div>



                    <div className='question-form-wrapper'>
                        <h3>{translate("Update Question")}.</h3>
                    </div>

                    <div>
                        <Editor
                            editorState={editorState}
                            onEditorStateChange={handleEditorChange}
                            handlePastedText={handlePastedText}
                            toolbarClassName="toolbar-class"
                            wrapperClassName="wrapper-class"
                            editorClassName="editor-class"
                        />
                    </div>
                    <div className='question-answer-form-error'>
                        <p>{error}</p>
                    </div>

                    <div className='post-question'>
                        <button type='submit'>{translate("Update")} </button>
                    </div>
                </div>

            </form>
        </>
    )
}
export default UpdateQuestionForm;  
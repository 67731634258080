import { useContext, useState } from 'react';
import userIcon from '../../assets/images/icons/user.svg';
import { UserContext } from '../../contexts/user-context';
import Popup from 'reactjs-popup';

import '../../assets/styles/user.css'
import UserProfile from '../user-profile/user-profile';
import { LanguageContext } from '../../contexts/language-context';

function User() {
  const { isLoggedIn, user } = useContext(UserContext);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const { translate } = useContext(LanguageContext);
  const handleUserClick = () => {
    setPopupOpen(true);
  };
  const closePopup = () => {
    setPopupOpen(false);
  };
  if (isLoggedIn) {
    return (
      <>
        <div className="user" onClick={handleUserClick}>
          <div className="user-icon" ><img src={userIcon} alt="" /></div>
          <p className="user-name">{user.userName}</p>
        </div>

        <Popup
          open={isPopupOpen}
          onClose={closePopup}
          modal
          centered
        >
          <div className='popup-content'>
            <button className='popup-close-button' onClick={closePopup}>
              &times;
            </button>
            <UserProfile />
          </div>
        </Popup>
      </>
    );
  } else {
    return (
      <a href='/login'>
        <div className="user">
          <div className="user-icon" ><img src={userIcon} alt="" /></div>
          <p className="user-name">{translate("Guest")}</p>
        </div>
      </a>
    );
  }
}

export default User;
import { getQuestionsByIdForAllApiUrl, getQuestionsForAllApiUrl, userToken } from '../../assets/api/common-data';
import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import { EditorState, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import commentIcon from '../../assets/images/icons/comment.svg';
import shareIcon from '../../assets/images/icons/share.svg';

import '../../assets/styles/question.css';
import { useNavigate } from 'react-router-dom';
import { LanguageContext } from '../../contexts/language-context';
import { Helmet } from "react-helmet";
import Moment from 'react-moment';

function SelectedQuestion({ questionid }) {
  const [questions, setQuestions] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [error, setError] = useState();
  const [limit, setLimit] = useState(10);
  const [selectedSort, setSelectedSort] = useState('recommended');
  const navigate = useNavigate()
  const { translate, changeLanguage } = useContext(LanguageContext);
  const [textFromRichText, setTextFromRichText] = useState('');
  const [questionId, setQuestionId] = useState('');
  const [keyWords, setkeyWords] = useState('');
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    fetchData();
    const storedSort = localStorage.getItem('sort');
    if (storedSort) {
      setSelectedSort(JSON.parse(storedSort));
    }
    let savedLanguage = localStorage.getItem('language');
    if (!savedLanguage) {
      localStorage.setItem('language', 'english');
    }
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${getQuestionsByIdForAllApiUrl}/${questionid}`);
      setQuestions(response.data.questions);
      setTotalPages(response.data.totalPages);

      response.data.questions.forEach(question => {
        const contentState = convertFromRaw(JSON.parse(question.question));
        const plainText = contentState.getPlainText();
        setTextFromRichText(plainText)
        setQuestionId(question.id)
        setkeyWords(question.keyWords)
        changeLanguage(question.language)
      });

    } catch (error) {
      setError('Failed to fetch items.');
    }
  };

  if (questions.length === 0) {
    // Render loading state or skeleton screen while fetching data
    return <div>No Questions found.</div>;
  }

  const handleSortChange = (event) => {
    const sort = event.target.value;
    setSelectedSort(sort);
    localStorage.setItem('sort', JSON.stringify(sort));
    window.location.reload();
  };

  const copyLinkToClipboard = () => {
    const currentUrl = window.location.href;
    console.log(currentUrl);
    navigator.clipboard.writeText(currentUrl)
      .then(() => {
        setIsCopied(true);
      })
      .catch((error) => {
        console.error('Failed to copy link: ', error);
      });
  };
  return (
    <>
      <div className=''>

        {questions.map((question) => (
          <>
            <div key={question.id} className='question-wrapper'>
              <h1>
                <a href={`/question/${question.id}`}>
                  {question.questionTitle}
                </a>
              </h1>
              
              {/*
              <a href={`/question/${question.id}`}>
                <div>
                  <Editor
                    editorState={EditorState.createWithContent(convertFromRaw(JSON.parse(question.question)))}
                    toolbarHidden
                    readOnly
                    wrapperClassName='wrapper-class'
                    editorClassName='editor-class-question-view' />
                </div>
              </a>
              */}
              <p className='question-created-time'><Moment fromNow>{question.createdAt}</Moment>                                        </p>


              <div className='single-question-status'>
                <div className='single-question-status-data'>
                  <img src={commentIcon} alt='view' />
                  <p>{question.answerCount}</p>
                </div>
                <div className='single-question-status-data question-share' onClick={copyLinkToClipboard} >
                  <img src={shareIcon} alt="share" />
                  <p>{isCopied ? translate("Link Copied") : translate("copy link")}</p>
                </div>
                <div className='single-question-button'>
                  <a href={`/addanswer/${question.id}`}>
                    <button>{translate("answer")}</button>
                  </a>
                </div>

                <div className='single-question-sort col-sm-hide'>
                  <p>{translate("sort by")}</p>
                  <select id='myDropdown' value={selectedSort} onChange={handleSortChange}>
                    <option value='recomended'>{translate("Recommended")} </option>
                    <option value='recent'>{translate("Recent")} </option>
                    <option value='like'>{translate("Like")} </option>
                  </select>
                </div>
              </div>
            </div>

          </>
        ))}

        <div className='single-question-sort small-single-question-sort col-md-hide'>
          <p>{translate("sort by")}</p>
          <select id='myDropdown' value={selectedSort} onChange={handleSortChange}>
            <option value='recomended'>{translate("Recommended")} </option>
            <option value='recent'>{translate("Recent")} </option>
            <option value='like'>{translate("Like")} </option>
          </select>
        </div>
      </div>

      <Helmet>
        <title>{truncateText(textFromRichText, 55)}</title>
        <meta name="description" content={truncateText(textFromRichText, 108)} />
        <meta name="keywords" content={keyWords} />
        <meta name="author" content="warkaser" />
        <meta property="og:title" content={textFromRichText} />
        <meta property="og:description" content={textFromRichText} />
        <meta property="og:image" content={""} />
        <meta property="og:url" content={`https://warkaser.com/question/${questionId}`} />
        <meta property="og:type" content="article" />
        <meta name="twitter:title" content={textFromRichText} />
        <meta name="twitter:description" content={textFromRichText} />
        <meta name="twitter:image" content="https://warkaser.com" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>

    </>
  );
}
function truncateText(text, maxLength) {
  if (text.length > maxLength) {
    return text.substring(0, maxLength - 3) + '...';
  }
  return text;
}

export default SelectedQuestion;
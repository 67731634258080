import React, { useContext } from 'react';
import { LanguageContext } from '../../contexts/language-context';

function PrivacyPolicyComponent() {
    const { language } = useContext(LanguageContext);

    return (
        <div className='footer-component-wrapper'>
            {language === "amharic" ? (
                <div className='footer-component-amharic'>
                    <h1>የግላዊነት መመሪያ</h1>
                    <p>መጨረሻ የተሻሻለው፡ [29/6/2016]</p>

                    <h3>1. መግቢያ</h3>
                    <p>የእርስዎ ግላዊነት ለእኛ አስፈላጊ ነው። ይህ የግላዊነት መመሪያ የእኛን ድረ-ገጽ ወይም አገልግሎታችንን ሲጠቀሙ የእርስዎን የግል መረጃ እንዴት እንደምንሰበስብ፣ እንደምንጠቀም እና እንደምንገልጥ ያብራራል።</p>

                    <h3>2. የምንሰበስበው መረጃ</h3>
                    <p>የሚከተሉትን የግል መረጃ ዓይነቶች ልንሰበስብ እንችላለን፡</p>
                    <ul>
                        <li>መለያ ሲፈጥሩ ወይም እኛን ሲያነጋግሩን የሚያቀርቡት መረጃ</li>
                        <li>በኩኪዎች እና ተመሳሳይ ቴክኖሎጂዎች አማካኝነት በራስ ሰር የሚሰበሰብ መረጃ</li>
                        <li>ከሶስተኛ ወገን ምንጮች የተገኘ መረጃ</li>
                    </ul>

                    <h3>3. የእርስዎን መረጃ እንዴት እንደምንጠቀም</h3>

                    <p>የእርስዎን የግል መረጃ ለሚከተሉት ዓላማዎች ልንጠቀምበት እንችላለን፡</p>

                    <ul>
                        <li>አገልግሎቶቻችንን ለማቅረብ እና ለማቆየት</li>
                        <li>የእርስዎን ተሞክሮ ለግል ለማበጀት እና ድረ-ገጻችንን ለማሻሻል</li>
                        <li>ከእርስዎ ጋር ለመገናኘት እና ለጥያቄዎችዎ ምላሽ ለመስጠት</li>
                        <li>የግብይት እና የማስተዋወቂያ ቁሳቁሶችን ለመላክ</li>
                        <li>ቴክኒካዊ ጉዳዮችን ወይም ማጭበርበርን ለማግኘት፣ ለመከላከል እና ለመፍታት</li>
                    </ul>

                    <h3>4. የእርስዎን መረጃ እንዴት እንደምንገልጽ</h3>
                    <p>የእርስዎን የግል መረጃ ለሚከተሉት ወገኖች ልንገልጽ እንችላለን፡</p>
                    <ul>
                        <li>ድር ጣቢያችንን ለመስራት እና አገልግሎቶቻችንን ለማቅረብ የሚረዱን አገልግሎት አቅራቢዎች</li>
                        <li>ከእኛ ጋር ህጋዊ ግዴታ ያለብን ወይም መብታችንን መጠበቅ ያለብን ሶስተኛ ወገኖች</li>
                        <li>በእርስዎ ፈቃድ ወይም በእርስዎ መመሪያ</li>
                    </ul>

                    <h3>5. የውሂብ ማቆየት</h3>
                    <p>በዚህ የግላዊነት ፖሊሲ ውስጥ የተገለጹትን ዓላማዎች ለመፈጸም አስፈላጊ እስከሆነ ድረስ የእርስዎን የግል መረጃ እናቆየዋለን፣ ረዘም ያለ የማቆያ ጊዜ ካልተፈለገ ወይም በሕግ ካልተፈቀደ በስተቀር።</p>

                    <h3>6. ደህንነት</h3>
                    <p>የእርስዎን የግል መረጃ ካልተፈቀደ መዳረሻ፣ አጠቃቀም ወይም ይፋ ከማድረግ ለመጠበቅ ምክንያታዊ እርምጃዎችን እንወስዳለን። ነገር ግን ምንም አይነት የበይነመረብ ወይም የኤሌክትሮኒክስ ማከማቻ 100% ደህንነቱ የተጠበቀ የመተላለፍ ዘዴ የለም።</p>

                    <h3>7. መብቶችህ</h3>
                    <p>የእርስዎን የግል መረጃ በተመለከተ አንዳንድ መብቶች አሉዎት፣ መረጃዎን የመድረስ፣ የማረም ወይም የመሰረዝ መብትን ጨምሮ። እነዚህን መብቶች ለመጠቀም ከፈለጉ እባክዎ ያግኙን።</p>

                    <h3>8. የልጆች ግላዊነት</h3>
                    <p>የእኛ ድረ-ገጽ እና አገልግሎታችን ከ13 አመት በታች ለሆኑ ግለሰቦች የታሰበ አይደለም። እያወቅን የግል መረጃን ከልጆች አንሰበስብም። ሳናውቀው ከልጁ የግል መረጃ እንደሰበሰብን ካመኑ፣ እባክዎ ያነጋግሩን።</p>

                    <h3>9. በዚህ የግላዊነት መመሪያ ላይ የተደረጉ ለውጦች</h3>
                    <p>ይህንን የግላዊነት ፖሊሲ ከጊዜ ወደ ጊዜ ማዘመን እንችላለን። አዲሱን የግላዊነት መመሪያ በዚህ ገጽ ላይ በመለጠፍ ማንኛውንም ለውጦች እናሳውቅዎታለን።</p>

                    <h3>10. ያግኙን</h3>
                    <p>ስለዚህ የግላዊነት ፖሊሲ ማንኛቸውም ጥያቄዎች ወይም ስጋቶች ካሉዎት፣ እባክዎን በ [ኢሜል ወይም ፌስቡክ] ያግኙን።</p>

                </div>
            ) : (
                <div className='footer-component-english'>

                    <h1>Privacy Policy</h1>

                    <p>Last updated: [Friday, March 08, 2024]</p>

                    <h3>1. Introduction</h3>

                    <p>Your privacy is important to us. This Privacy Policy explains how we collect, use, and disclose your personal information when you use our website or services.</p>

                    <h3>2. Information We Collect</h3>

                    <p>We may collect the following types of personal information:</p>

                    <ul>
                        <li>Information you provide when creating an account or contacting us</li>
                        <li>Information collected automatically through cookies and similar technologies</li>
                        <li>Information obtained from third-party sources</li>
                    </ul>

                    <h3>3. How We Use Your Information</h3>

                    <p>We may use your personal information for the following purposes:</p>

                    <ul>
                        <li>To provide and maintain our services</li>
                        <li>To personalize your experience and improve our website</li>
                        <li>To communicate with you and respond to your inquiries</li>
                        <li>To send you marketing and promotional materials</li>
                        <li>To detect, prevent, and address technical issues or fraud</li>
                    </ul>

                    <h3>4. How We Disclose Your Information</h3>

                    <p>We may disclose your personal information to the following parties:</p>

                    <ul>
                        <li>Service providers who assist us in operating our website and providing our services</li>
                        <li>Third parties with whom we have a legal obligation or need to protect our rights</li>
                        <li>With your consent or at your direction</li>
                    </ul>

                    <h3>5. Data Retention</h3>

                    <p>We will retain your personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law.</p>

                    <h3>6. Security</h3>

                    <p>We take reasonable measures to protect your personal information from unauthorized access, use, or disclosure. However, no method of transmission over the internet or electronic storage is 100% secure.</p>

                    <h3>7. Your Rights</h3>

                    <p>You have certain rights regarding your personal information, including the right to access, correct, or delete your information. Please contact us if you wish to exercise these rights.</p>

                    <h3>8. Children's Privacy</h3>

                    <p>Our website and services are not intended for individuals under the age of 13. We do not knowingly collect personal information from children. If you believe we have inadvertently collected personal information from a child, please contact us.</p>

                    <h3>9. Changes to this Privacy Policy</h3>

                    <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>

                    <h3>10. Contact Us</h3>

                    <p>If you have any questions or concerns about this Privacy Policy, please contact us at [Email or Facebook].</p>
                </div>
            )
            }
        </div >
    );
}

export default PrivacyPolicyComponent;
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/home';
import { UserProvider } from './contexts/user-context';
import Login from './pages/login';
import Signup from './pages/signup';
import Verify from './pages/verify';
import UpdateProfile from './pages/update-profile';
import PrivateRoutes from './routes/protected-route';
import ChangePassword from './pages/change-password';
import UpdateProfilePicture from './pages/update-profile-picture';
import AddQuestion from './pages/add-question';
import QuestionAnswers from './pages/question-answers';
import { QuestionIdProvider } from './contexts/questionId-context';
import AddAnswer from './pages/add-answer';
import { AnonymousUserProvider } from './contexts/anonymous-user-context';
import MyQuestion from './pages/my-question';
import UpdateQuestion from './pages/update-question';
import { LanguageProvider } from './contexts/language-context';
import AboutWarka from './pages/about-warka';
import TermsAndConditions from './pages/terms-and-conditions';
import PrivacyPolicy from './pages/privacy-policy';
import Notifications from './pages/notifications';
import Search from './pages/search';
import { SearchProvider } from './contexts/search-context';
import ForgotPassword from './pages/forgot-password';
import ChangeLostPassword from './pages/change-lost-password';
import MyAnswer from './pages/my-answer';
import { CategoriesProvider } from './contexts/category-context';


function App() {
  return (
    <div>
      <BrowserRouter>
        <AnonymousUserProvider>

          <LanguageProvider>
            <QuestionIdProvider>
              <UserProvider>
                <SearchProvider>

                  <CategoriesProvider>

                    <Routes>
                      <Route path='/' element={<Home />} />
                      <Route path='/login' element={<Login />} />
                      <Route path='/signup' element={<Signup />} />
                      <Route path='/verify' element={<Verify />} />
                      <Route path='/forgotpassword' element={<ForgotPassword />} />
                      <Route path='/changelostpassword' element={<ChangeLostPassword />} />

                      <Route path='/question/:questionid' element={<QuestionAnswers />} />
                      <Route path='/addanswer/:questionid' element={<AddAnswer />} />

                      <Route path='/search' element={<Search />} />

                      <Route path='/aboutwarka' element={<AboutWarka />} />
                      <Route path='/termsandconditions' element={<TermsAndConditions />} />
                      <Route path='/privacypolicy' element={<PrivacyPolicy />} />
                      <Route path='/myanswers' element={<MyAnswer />} />
                      <Route path='/addquestion' element={<AddQuestion />} />
                      <Route path='/updatequestion/:questionId' element={<UpdateQuestion />} />
                      <Route path='/myquestion' element={<MyQuestion />} />

                      <Route element={<PrivateRoutes />}>
                        <Route path='/updateprofile' element={<UpdateProfile />} />
                        <Route path='/changepassword' element={<ChangePassword />} />
                        <Route path='/updateprofilepicture' element={<UpdateProfilePicture />} />
                        <Route path='/notifications' element={<Notifications />} />
                      </Route>

                    </Routes>
                  </CategoriesProvider>
                </SearchProvider>

              </UserProvider>
            </QuestionIdProvider>
          </LanguageProvider>
        </AnonymousUserProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;


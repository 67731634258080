import { useContext } from 'react';
import '../../assets/styles/footer-component.css'
import { LanguageContext } from '../../contexts/language-context';
function TermsAndConditionsComponent() {
    const { language } = useContext(LanguageContext);

    return (

        <div className='footer-component-wrapper'>
            {language === "amharic" ? (
                <div className='footer-component-amharic'>
                    <h1>የዋርካስር ውሎች እና ሁኔታዎች</h1>
                    <h2>የጀመረበት ቀን፡ [29/6/2016]</h2>
                    <p>እባክዎ ዋርካስር ("ፕላትፎርም") ከመጠቀምዎ በፊት እነዚህን ውሎች እና ሁኔታዎች ("ውሎች") በጥንቃቄ ያንብቡ። እነዚህ ውሎች ዋርካስር የሚቀርቡትን ማንኛውንም ይዘቶች፣ ባህሪያት ወይም አገልግሎቶች ጨምሮ የመድረክን መዳረሻ እና አጠቃቀም ይቆጣጠራሉ። ፕላትፎርሙን በመድረስ ወይም በመጠቀም፣ በእነዚህ ውሎች ለመገዛት ስምምነትዎን ያረጋግጣሉ።</p>
                    <h3>1. የተጠቃሚ ኃላፊነቶች</h3>
                    <h4>1.1 መለያ መፍጠር</h4>
                    <p>የመሣሪያ ስርዓቱ የተወሰኑ ባህሪያትን ለመድረስ መለያ መፍጠር ያስፈልግዎታል። መለያ ሲፈጥሩ ትክክለኛ እና የተሟላ መረጃ መስጠት አለቦት። የመለያ ምስክርነቶችን ሚስጥራዊነት የመጠበቅ እና በመለያዎ ስር ለሚደረጉ እንቅስቃሴዎች በሙሉ ሀላፊነት እርስዎ ብቻ ነዎት።</p>
                    <h4>1.2 የተጠቃሚ ይዘት</h4>
                    <p>የመድረኩ ተጠቃሚ እንደመሆንዎ መጠን ጥያቄዎችን፣ መልሶችን፣ አስተያየቶችን ወይም ሌላ ይዘት ("የተጠቃሚ ይዘት") መፍጠር፣መለጠፍ ወይም ማጋራት ይችላሉ። ለመድረኩ የሚያበረክቱት ማንኛውም የተጠቃሚ ይዘት ትክክለኛ፣ ህጋዊ እና የሶስተኛ ወገን መብቶችን የማይጥስ መሆኑን ወክለው ዋስትና ይሰጣሉ።</p>
                    <h4>1.3 የተጠቃሚ ምግባር</h4>
                    <p>ፕላትፎርሙን ከሚመለከታቸው ህጎች እና መመሪያዎች ጋር በሚስማማ መልኩ ለመጠቀም ተስማምተሃል። በማንኛውም ጎጂ፣ አፀያፊ፣ ወይም የሌሎችን መብት የሚጥስ ድርጊት ውስጥ መግባት የለብዎትም። የተከለከሉ ተግባራት ትንኮሳ፣ አይፈለጌ መልእክት መላክ፣ ማስመሰል ወይም ተንኮል አዘል ሶፍትዌሮችን መስቀል ያካትታሉ።</p>

                    <h3>2. ባለቤትነት እና አእምሯዊ ንብረት</h3>

                    <h4>2.1 የመሣሪያ ስርዓት ባለቤትነት</h4>
                    <p>ዋርካስር እና ተጓዳኝ አካላት ሁሉም ተዛማጅ የአእምሮአዊ ንብረት መብቶችን ጨምሮ በመድረክ ላይ ሁሉም መብቶች አላቸው። እነዚህ ውሎች የመሣሪያ ስርዓቱ ወይም ይዘቱ ምንም አይነት የባለቤትነት መብት አይሰጡዎትም።</p>
                    <h4>2.2 የተጠቃሚ ይዘት ባለቤትነት</h4>
                    <p>የፈጠሩት እና በፕላትፎርሙ ላይ የለጠፉት የተጠቃሚ ይዘት ባለቤትነትን እንደያዙ ይቆያሉ። ነገር ግን የተጠቃሚ ይዘትን በመለጠፍ ፕላትፎርሙን ለማስተዋወቅ እና ለማስተዋወቅ ለዋርካስር ልዩ ያልሆነ ፣አለምአቀፍ ፣ከሮያሊቲ ነፃ ፍቃድ ትሰጣላችሁ።</p >
                    <h3>3. የይዘት ማስተካከያ እና ማስወገድ</h3>

                    <h4>3.1 ልከኝነት</h4>
                    <p>ዋርካስር እነዚህን ውሎች የሚጥስ ወይም በሌላ መልኩ የሚቃወመውን ማንኛውንም የተጠቃሚ ይዘት የመቆጣጠር፣ የማረም ወይም የማስወገድ መብቱ የተጠበቀ ነው። እነዚህን ውሎች በተደጋጋሚ የሚጥሱ የተጠቃሚ መለያዎችን ማገድ ወይም ማቋረጥ እንችላለን።</p>
                    <h4>3.2 መለያየት </ h4>
                    <p>ተጠቃሚዎች መልሶችን ወይም ጥያቄዎችን መሰረዝ ባይችሉም፣ ጥያቄዎቻቸውን ወይም መልሶቻቸውን ከመለያቸው እንዲገለሉ መጠየቅ ይችላሉ። መለያየት በተጠቃሚው እና በይዘቱ መካከል ያለውን ግንኙነት ያስወግዳል ነገር ግን ይዘቱን ሙሉ በሙሉ ከመድረክ ላይ ማስወገድ አይችልም።</p>
                    <h3>4. የክህደት ቃል እና የተጠያቂነት ገደብ</h3>

                    <h4>4.1 የይዘት ትክክለኛነት</h4>
                    <p>ዋርካስር የማንኛውንም የተጠቃሚ ይዘት ወይም በፕላትፎርሙ ላይ የቀረበውን መረጃ ትክክለኛነት፣ አስተማማኝነት ወይም ሙሉነት ዋስትና አይሰጥም። ተጠቃሚዎች በእሱ ላይ ከመታመንዎ በፊት መረጃውን በተናጥል እንዲያረጋግጡ ይመከራሉ።</p>
                    <h4>4.2 የሶስተኛ ወገን ይዘት</h4>
                    <p>የመድረኩ የሶስተኛ ወገን ድር ጣቢያዎች ወይም ይዘቶች አገናኞችን ሊይዝ ይችላል። ዋርካስር ለእንደዚህ አይነት ይዘት ትክክለኛነት ወይም ተገኝነት ምንም አይነት ሃላፊነት አይወስድም ወይም አይወስድም። ከሶስተኛ ወገን ድር ጣቢያዎች ወይም አገልግሎቶች ጋር ያለዎት ግንኙነት በእርስዎ እና በሶስተኛ ወገን መካከል ብቻ ነው።</p>
                    <h4>4.3 የተጠያቂነት ገደብ</h4>
                    <p>በምንም አይነት ሁኔታ ዋርካስር ከመድረክ አጠቃቀም ጋር በተያያዘ ለሚደርስ ማንኛውም ቀጥተኛ ያልሆነ፣ አጋጣሚ፣ ልዩ፣ ተከታይ ወይም ለቅጣት ጉዳት ተጠያቂ አይሆንም።</p>

                    <h3>5. መቋረጥ</h3>

                    <p>ዋርካስር በማንኛውም ጊዜ ያለምክንያትም ሆነ ያለቅድመ ማስታወቂያ የመድረክን መዳረሻ ሊያግድ ወይም ሊያቋርጥ ይችላል። እነዚህ ውሎች ከተቋረጡ በኋላ የዋርካስርን መብትና ጥቅም ለማስጠበቅ በሚፈለገው መጠን ይኖራሉ።</p>

                    <h3>6. የአስተዳደር ህግ እና የክርክር አፈታት</h3>

                    <p>እነዚህ ውሎች የሚተዳደሩት እና የሚነገሩት በ[ኢትዮጵያ] ህጎች መሰረት ነው። </p>
                    <h3>7. ማሻሻያዎች</h3>

                    <p>ዋርካስር እነዚህን ውሎች በማንኛውም ጊዜ ያለቅድመ ማስታወቂያ የማሻሻል ወይም የማዘመን መብቱ የተጠበቀ ነው። ለማንኛውም ለውጦች እነዚህን ውሎች በየጊዜው መገምገም የእርስዎ ኃላፊነት ነው። ከማናቸውም ማሻሻያዎች በኋላ የመድረክን መጠቀም መቀጠል የተሻሻሉትን ውሎች መቀበልዎን ያካትታል።</p>

                    <p>የዋርካስር መድረክን በመጠቀም እነዚህን ውሎች እና ሁኔታዎች እንዳነበቡ፣ እንደተረዱ እና እንደተስማሙ እውቅና ይሰጣሉ። በእነዚህ ውሎች ውስጥ በማንኛውም ክፍል ካልተስማሙ፣እባክዎ ፕላትፎርሙን ከመጠቀም ይቆጠቡ።</p>

                    <p>እነዚህን ውሎች በተመለከተ ማንኛቸውም ጥያቄዎች ወይም ስጋቶች ካሉዎት፣ እባክዎን በ [ኢሜል ወይም ፌስቡክ] ያግኙን።</p>

                    <p>እነዚህ ውሎች እና ሁኔታዎች ለመጨረሻ ጊዜ የተዘመኑት [29/6/2016] ላይ ነው።</p>
                </div>

            ) : (
                <div className='footer-component-english'>
                    <h1>Terms and Conditions for Warkaser</h1>
                    <h2>Effective Date: [Friday, March 08, 2024]</h2>
                    <p>Please carefully read these Terms and Conditions ("Terms") before using Warkaser ("the Platform"). These Terms govern your access to and use of the Platform, including any content, features, or services offered by Warkaser. By accessing or using the Platform, you signify your agreement to be bound by these Terms.</p>
                    <h3>1. User Responsibilities</h3>

                    <h4>1.1 Account Creation</h4>
                    <p>You are required to create an account to access certain features of the Platform. When creating an account, you must provide accurate and complete information. You are solely responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.</p>

                    <h4>1.2 User Content</h4>
                    <p>As a user of the Platform, you may create, post, or share questions, answers, comments, or other content ("User Content"). You represent and warrant that any User Content you contribute to the Platform is accurate, lawful, and does not infringe upon the rights of any third party.</p>

                    <h4>1.3 User Conduct</h4>
                    <p>You agree to use the Platform in a manner consistent with applicable laws and regulations. You shall not engage in any conduct that is harmful, offensive, or violates the rights of others. Prohibited activities include, but are not limited to, harassment, spamming, impersonation, or uploading malicious software.</p>

                    <h3>2. Ownership and Intellectual Property</h3>

                    <h4>2.1 Platform Ownership</h4>
                    <p>Warkaser and its affiliated entities own all rights, title, and interest in the Platform, including all associated intellectual property rights. These Terms do not grant you any ownership rights to the Platform or its content.</p>

                    <h4>2.2 User Content Ownership</h4>
                    <p>You retain ownership of the User Content you create and post on the Platform. However, by posting User Content, you grant Warkaser a non-exclusive, worldwide, royalty-free license to use, reproduce, modify, adapt, publish, and distribute the User Content for the purpose of operating and promoting the Platform.</p>

                    <h3>3. Content Moderation and Removal</h3>

                    <h4>3.1 Moderation</h4>
                    <p>Warkaser reserves the right to moderate, edit, or remove any User Content that violates these Terms or is otherwise objectionable. We may also suspend or terminate user accounts that repeatedly violate these Terms.</p>

                    <h4>3.2 Detachment</h4>
                    <p>While users cannot delete answers or questions, they can request detachment of their questions or answers from their account. Detachment removes the association between the user and the content but may not remove the content entirely from the Platform.</p>

                    <h3>4. Disclaimers and Limitation of Liability</h3>

                    <h4>4.1 Content Accuracy</h4>
                    <p>Warkaser does not guarantee the accuracy, reliability, or completeness of any User Content or information provided on the Platform. Users are advised to verify the information independently before relying on it.</p>

                    <h4>4.2 Third-Party Content</h4>
                    <p>The Platform may contain links to third-party websites or content. Warkaser does not endorse or assume any responsibility for the accuracy or availability of such content. Your interactions with third-party websites or services are solely between you and the third party.</p>

                    <h4>4.3 Limitation of Liability</h4>
                    <p>In no event shall Warkaser be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or related to the use of the Platform, even if advised of the possibility of such damages.</p>

                    <h3>5. Termination</h3>

                    <p>Warkaser may suspend or terminate your access to the Platform at any time, with or without cause, and without prior notice. Upon termination, these Terms shall survive to the extent necessary to protect the rights and interests of Warkaser.</p>

                    <h3>6. Governing Law and Dispute Resolution</h3>

                    <p>These Terms shall be governed by and construed in accordance with the laws of [Ethiopia].</p>
                    
                    <h3>7. Amendments</h3>

                    <p>Warkaser reserves the right to modify or update these Terms at any time without prior notice. It is your responsibility to review these Terms periodically for any changes. Continued use of the Platform after any modifications constitutes your acceptance of the revised Terms.</p>

                    <p>By using the Warkaser Platform, you acknowledge that you have read, understood, and agreed to these Terms and Conditions. If you do not agree with any part of these Terms, please refrain from using the Platform.</p>
                    
                    <p>If you have any questions or concerns regarding these Terms, please contact us at [Email or Facebook].</p>

                    <p>These Terms and Conditions were last updated on [Friday, March 08, 2024].</p>
                </div>
            )
            }
        </div >

    )
}
export default TermsAndConditionsComponent;  
import { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../assets/styles/login-signup.css'
import axios from 'axios';
import { resendVerificationCodeApiUrl, userToken, verifyApiUrl } from '../assets/api/common-data';
import { LanguageContext } from '../contexts/language-context';

function Verify() {
    const [id, setId] = useState('');
    const [code, setCode] = useState('');
    const [error, setError] = useState([]);
    const navigate = useNavigate()
    const { translate } = useContext(LanguageContext);

    useEffect(() => {
        const storedUser = JSON.parse(localStorage.getItem('userProfile'))
        setId(storedUser?.user?.id)
        if (storedUser?.user?.isVerified == true) {
            navigate('/')
        }
    }, [id])

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        if (name === 'verificationCode') {
            setCode(value);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const storedUser = localStorage.getItem('userProfile');
        try {
            if (storedUser) {
                const response = await axios.post(`${verifyApiUrl}/${id}`, { code },
                    {
                        headers: {
                            Authorization: `Bearer ${userToken}`,
                        },
                    });
                localStorage.setItem('userProfile', JSON.stringify(response.data));
                navigate('/')
                window.location.reload();
                setCode('');
            } else {
                setError(['user not found']);
            }
        } catch (error) {
            if (error.response) {
                setError([error.response.data.message]);
            } else {
                setError(['someting went wrong']);
            }
        }
    };

    const handleResendVerification = async (event) => {
        event.preventDefault();
        const storedUser = localStorage.getItem('userProfile');
        try {
            if (storedUser) {
                const response = await axios.post(resendVerificationCodeApiUrl, null,
                    {
                        headers: {
                            Authorization: `Bearer ${userToken}`,
                        },
                    });
                setError([response.data.message])
            } else {
                setError(['user not found']);
            }
        } catch (error) {
            console.log(error);
            if (error.response) {
                setError([error.response.data.message]);
            } else {
                setError(['someting went wrong']);
            }
        }
    };
    const handleSkip = async () => {
        navigate('/')
        window.location.reload();
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="loginsignup-container">
                <h3>{translate("Verify")} </h3>
                <label>{translate("Verification Code")} </label>
                <input
                    type="text"
                    name="verificationCode"
                    value={code}
                    onChange={handleInputChange}
                    placeholder={translate("Verification Code")}
                />
                {error.length > 0 &&
                    <div className='loginsignup-error'>
                        {error.map((errorData) => (
                            <p>{errorData}</p>
                        ))}
                    </div>
                }
                <div className='verification-button'>
                    <button type="submit">{translate("Verify")} </button>
                    <button onClick={handleSkip} >{translate("Skip")} </button>
                </div>
                <p className="loginsignup-login">{translate("Didn't receive a code")}? &nbsp;
                    <span>
                        <Link to='/login' onClick={handleResendVerification} className='loginsignup-link'>
                            {translate("Resend")}
                        </Link>
                    </span>
                </p>

            </div>
        </form>
    );
}

export default Verify;
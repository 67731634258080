import { useContext, useState } from 'react';
import { EditorState, convertToRaw, Modifier, InlineStyle } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import '../../assets/styles/question-answer-form.css'
import { createQuestionApiUrl, userToken } from '../../assets/api/common-data';
import axios from 'axios';
import { UserContext } from '../../contexts/user-context';
import MyQuestion from './my-question';
import { LanguageContext } from '../../contexts/language-context';
import { AnonymousUserContext } from '../../contexts/anonymous-user-context';
 
function QuestionForm() {
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
    const [refresh, setRefresh] = useState(false);
    const [error, setError] = useState('');
    const { translate } = useContext(LanguageContext);
    const { user } = useContext(UserContext);
    const { anonymousUser } = useContext(AnonymousUserContext);

    const handleEditorChange = (newEditorState) => {
        setRefresh(false)
        setEditorState(newEditorState);
    };

    const handlePastedText = (text, html, editorState) => {
        console.log(text);

        // Extract the font size from the HTML
        const fontSizeRegex = /font-size:\s*(\d+)px;/g;
        const fontSizeMatches = html ? [...html.matchAll(fontSizeRegex)] : [];
        const fontSize = fontSizeMatches.length > 0 ? fontSizeMatches[0][1] : null;

        // Apply the new inline style to the pasted content
        const contentState = editorState.getCurrentContent();
        const selectionState = editorState.getSelection();

        let newContentState = Modifier.replaceText(
            contentState,
            selectionState,
            text
        );

        if (fontSize) {
            newContentState = Modifier.applyInlineStyle(
                newContentState,
                selectionState,
                `FONT_SIZE_${fontSize}`
            );
        }

        // Update the editor state
        const newEditorState = EditorState.push(
            editorState,
            newContentState,
            'insert-characters'
        );
        setEditorState(newEditorState);

        return 'handled';
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const contentState = editorState.getCurrentContent();
            const contentIsEmpty = !contentState.hasText();
            if (contentIsEmpty) {
                setError('Empty question. Please enter some content.');
                setEditorState('')
                setRefresh(false)
            } else {
                try {
                    const questionContent = JSON.stringify(convertToRaw(editorState.getCurrentContent()));
                    const response = await axios.post(createQuestionApiUrl, { question: questionContent, deviceId: anonymousUser },
                        {
                            headers: {
                                Authorization: `Bearer ${userToken}`,
                            },
                        }
                    );
                    setEditorState('')
                    setRefresh(true)
                    setError('')
                } catch (error) {
                    if (error.response) {
                        const { data } = error.response;
                        setError(data.message)
                    } else if (error.request) {
                        setError('Something went wrong');
                    } else {
                        setError('Something went wrong');
                    }
                }
            }
        } catch (error) {
            setError('Empty question. Please enter some content.');

        }

    }

    return (
        <>
            <form onSubmit={handleSubmit}>

                <div className='question-answer-form-wrapper'>
                    <div className='question-form-wrapper'>
                        <h3>{translate("Add Question below")}.</h3>
                    </div>

                    <div>
                        <Editor
                            editorState={editorState}
                            onEditorStateChange={handleEditorChange}
                            handlePastedText={handlePastedText}
                            toolbarClassName="toolbar-class"
                            wrapperClassName="wrapper-class"
                            editorClassName="editor-class"
                        />
                    </div>
                    <div className='question-answer-form-error'>
                        <p>{error}</p>
                    </div>

                    <div className='post-question'>
                        <button type='submit'>{translate("Submit")} </button>
                    </div>

                </div>
            </form>
            <MyQuestion refresh={refresh} />
        </>
    )
}
export default QuestionForm;  
import commentIcon from '../../assets/images/icons/comment.svg';
import { apiUrlForProfilePicture, deleteQuestionApiUrl, getMyQuestionsApiUrl, myAnswerApiUrl, myAnswersApiUrl, userToken } from '../../assets/api/common-data';
import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import { EditorState, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import acceptIcon from '../../assets/images/icons/accept.svg'
import rejectIcon from '../../assets/images/icons/reject.svg'
import deleteIcon from '../../assets/images/icons/delete.svg'
import updateIcon from '../../assets/images/icons/update.svg'
import underReviewIcon from '../../assets/images/icons/underReview.svg'
import Popup from 'reactjs-popup';
import InfiniteScroll from 'react-infinite-scroll-component';
import userIcon from '../../assets/images/icons/user-icon.svg'
import viewIcon from '../../assets/images/icons/view.svg'
import likeIcon from '../../assets/images/icons/like.svg'
import disLikeIcon from '../../assets/images/icons/dislike.svg'
import Moment from 'react-moment';
import '../../assets/styles/question.css';
import { useNavigate } from 'react-router-dom';
import { LanguageContext } from '../../contexts/language-context';
import { AnonymousUserContext } from '../../contexts/anonymous-user-context';

function MyAnswerComponent({ refresh }) {
  const [answers, setAnswers] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [error, setError] = useState();
  const [limit, setLimit] = useState(5);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [questionId, setQuestionId] = useState();
  const [hasMore, setHasMore] = useState(false);
  const navigate = useNavigate()
  const { translate } = useContext(LanguageContext);
  const { anonymousUser } = useContext(AnonymousUserContext);

  useEffect(() => {
    fetchData();
  }, [anonymousUser]);

  useEffect(() => {
    refreshFetch();
  }, [refresh]);

  const refreshFetch = async () => {
    if (refresh) {
      fetchData();
    }
  };
  console.log(anonymousUser);

  const fetchData = async () => {
    try {
      if (anonymousUser) {
        let fetchUrl = `${myAnswersApiUrl}?page=${page}&limit=${limit}&deviceId=${anonymousUser}`;
        const response = await axios.get(fetchUrl, {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        });
        // setAnswers(response.data.answers);
        // setTotalPages(response.data.totalPages);
        const uniqueAnswers = response.data.answers.filter(
          (newAnswer) => !answers.some((existingAnswer) => existingAnswer.id === newAnswer.id)
        );
        setAnswers((prevData) => [...prevData, ...uniqueAnswers]);
        setPage(response.data.currentPage + 1);
        setTotalCount(response.data.totalCount);
        setTotalPages(response.data.totalPages)
        if (response.data.currentPage >= response.data.totalPages) {
          setHasMore(false);
        } else {
          setHasMore(true)
        }
      }
    } catch (error) {
      setError('Failed to fetch items.');
    }
  };

  if (answers.length === 0) {
    // Render loading state or skeleton screen while fetching data
    return <div>{translate("You have not posted any answers")}.</div>;
  }

  return (
    <>
      <InfiniteScroll
        dataLength={answers.length}
        next={fetchData}
        hasMore={hasMore}
        loader={<h4>Loading...</h4>}
      >
        <div className=''>
          <h3>{translate("My Answers")}</h3>
          {answers.map((answer) => (
            <div key={answer.id} className='question-wrapper'>

              <a href={`/addanswer/${answer.question.id}`}>
                <div>
                  <Editor
                    editorState={EditorState.createWithContent(convertFromRaw(JSON.parse(answer.question.question)))}
                    toolbarHidden
                    readOnly
                    wrapperClassName='wrapper-class'
                    editorClassName='editor-class-question-view' />
                </div>
              </a>
              <p className='question-created-time'><Moment fromNow>{answer.question.createdAt}</Moment>                                        </p>

              <a href={`/addanswer/${answer.question.id}`}>
                <div className='answer-wrapper'>
                  <div className='single-answer' key={answer.id}>


                    <div>
                      <Editor
                        editorState={EditorState.createWithContent(convertFromRaw(JSON.parse(answer.answer)))}
                        toolbarHidden
                        readOnly
                        wrapperClassName='wrapper-class'
                        editorClassName='editor-class-answer-view'
                      />
                    </div>
                    <p className='answer-profile-time'><Moment fromNow>{answer.createdAt}</Moment>                                        </p>


                    <div className='single-answer-status'>
                      <div className='single-answer-status-data'>
                        <img src={viewIcon} alt="view" />
                        <p>{answer.view}</p>
                      </div>
                      <div className='single-answer-status-data'>
                        <img src={likeIcon} alt="like" />
                        <p>{answer.like}</p>
                      </div>
                      <div className='single-answer-status-data'>
                        <img src={disLikeIcon} alt="dislike" />
                        <p>{answer.dislike}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </a>

            </div>
          ))}
        </div>
      </InfiniteScroll>


    </>
  );
}

export default MyAnswerComponent;
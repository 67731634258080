import NavBar from "../components/navbar/navbar";
import RelatedQuestion from "../components/question/related-questions";
import "../assets/styles/home.css"
import Footer from "../components/footer/footer";
import UpdateQuestionForm from "../components/question/update-question";
import { useParams } from "react-router-dom";

function UpdateQuestion() {
    const { questionId } = useParams();
    return (
        <>
            <NavBar />
            <div className='home-wrapper'>
                <div className='col-xl-1 '>
                </div>
                <div className="col-xl-6 ">
                    <div className=' '>
                        <UpdateQuestionForm  questionId={questionId}/>
                    </div>
                    <div className=' '>
                        
                    </div>

                </div>
                <div className="col-xl-3">
                    <div className=' '>
                        <RelatedQuestion />
                    </div>
                </div>
                <div className="col-xl-2">

                </div>
            </div>
            <Footer/>
        </>
    )
}

export default UpdateQuestion;
import '../../assets/styles/answer.css'
import userIcon from '../../assets/images/icons/user-icon.svg'
import viewIcon from '../../assets/images/icons/view.svg'
import likeIcon from '../../assets/images/icons/like.svg'
import disLikeIcon from '../../assets/images/icons/dislike.svg'
import shareIcon from '../../assets/images/icons/share.svg'

import { useContext, useEffect, useRef, useState } from 'react'
import axios from 'axios'
import { apiUrlForProfilePicture, getAnswersApiUrl, postLikeAndDislikeApiUrl, userToken, viewApiUrl } from '../../assets/api/common-data'
import { EditorBlock, EditorState, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Moment from 'react-moment'
import { Waypoint } from 'react-waypoint';
import { AnonymousUserContext } from '../../contexts/anonymous-user-context'
import InfiniteScroll from 'react-infinite-scroll-component';
import { LanguageContext } from '../../contexts/language-context'

function AnswerForSelectedQuestion({ questionid }) {
    const [answers, setAnswers] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [page, setPage] = useState(1);
    const [toatalPages, setTotalPages] = useState(1);

    const [error, setError] = useState();
    const [limit, setLimit] = useState(5);
    const { anonymousUser } = useContext(AnonymousUserContext);
    const [expandedAnswerId, setExpandedAnswerId] = useState(null);
    const [characterCount, setCharacterCount] = useState({});

    const [textFromRichText, setTextFromRichText] = useState({});
    const [showFullText, setShowFullText] = useState(false);
    const sort = localStorage.getItem('sort');
    const [hasMore, setHasMore] = useState(false);
    const [visibleAnswerIds, setVisibleAnswerIds] = useState([]);
    const [fetchedIds, setFetchedIds] = useState([]);
    const { translate } = useContext(LanguageContext);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            let fetchUrl = `${getAnswersApiUrl}?page=${page}&limit=${limit}&questionId=${questionid}&sort=${sort}`;
            const response = await axios.get(fetchUrl);
            const uniqueAnswers = response.data.answers.filter(
                (newAnswer) => !answers.some((existingAnswer) => existingAnswer.id === newAnswer.id)
            );
            setAnswers((prevData) => [...prevData, ...uniqueAnswers]);
            setPage(response.data.currentPage + 1);
            setTotalCount(response.data.totalCount);
            setTotalPages(response.data.totalPages)
            const counts = {};
            const texts = {};

            response.data.answers.forEach(answer => {
                const contentState = convertFromRaw(JSON.parse(answer.answer));
                const characterCount = contentState.getPlainText().length;
                counts[answer.id] = characterCount;
                const plainText = contentState.getPlainText();
                texts[answer.id] = plainText;

            });
            setTextFromRichText((prevText) => ({ ...prevText, ...texts }));
            setCharacterCount((prevCounts) => ({ ...prevCounts, ...counts }));

            if (response.data.currentPage >= response.data.totalPages) {
                setHasMore(false);
            } else {
                setHasMore(true)
            }
        } catch (error) {
            setError('Failed to fetch answers.');
        }
    };
    const handleClick = async (answerId, type) => {
        try {
            let fetchUrl = `${postLikeAndDislikeApiUrl}`;
            const response = await axios.post(fetchUrl, { answerId, clickedType: type, deviceId: anonymousUser },
                {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                }
            );
            setAnswers(prevAnswers => {
                return prevAnswers.map(answer => {
                    if (answer.id === answerId) {
                        return {
                            ...answer,
                            like: response.data.answer.like,
                            dislike: response.data.answer.dislike

                        };
                    }
                    return answer;
                });
            });
        } catch (error) {
        }
    };

    const handleWaypointEnter = async (answerId) => {
        try {
            if (visibleAnswerIds.includes(answerId)) {
                let fetchUrl = `${viewApiUrl}?answerId=${answerId}`;
                await axios.post(fetchUrl);
                setAnswers((prevAnswers) => {
                    return prevAnswers.map((answer) => {
                        if (answer.id === answerId) {
                            return {
                                ...answer,
                                view: answer.view + 1, // Update the view count
                            };
                        }
                        return answer;
                    });
                });
            }
        } catch (error) {
            console.error('Failed to mark answer as viewed:', error);
        }
    }

    const handleWaypointEnterVisible = async (answerId) => {
        setVisibleAnswerIds((prevVisibleAnswerIds) => {
            if (prevVisibleAnswerIds.includes(answerId)) {
                handleWaypointEnter(answerId)
                return prevVisibleAnswerIds; // ID already exists, no need to add it again
            }
            handleWaypointEnter(answerId)
            return [...prevVisibleAnswerIds, answerId]; // Add the ID to the state
        });
    };


    useEffect(() => {
        // Check if the visibleAnswerIds array has changed
        if (visibleAnswerIds.length > fetchedIds.length) {
            const newIds = visibleAnswerIds.filter((id) => !fetchedIds.includes(id));
            newIds.forEach(async (id) => {
                let fetchUrl = `${viewApiUrl}?answerId=${id}`;
                try {
                    await axios.post(fetchUrl);
                    setFetchedIds((prevFetchedIds) => [...prevFetchedIds, id]); // Add the ID to the fetchedIds state
                } catch (error) {
                    // Handle the error if needed
                }
            });
        }
    }, [visibleAnswerIds]);

    /************************** */
    const handleScroll = () => {
        const visibleIds = [];
        const windowHeight = window.innerHeight;

        document.querySelectorAll('.waypoint').forEach((waypoint) => {
            const rect = waypoint.getBoundingClientRect();
            const isVisible = rect.top >= 0 && rect.bottom <= windowHeight;

            if (isVisible) {
                visibleIds.push(waypoint.getAttribute('data-answer-id'));
            }
        });
        visibleIds.forEach((id) => {
            handleWaypointEnterVisible(id);
        });
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const handleExpandAnswer = (answerId) => {
        setExpandedAnswerId(answerId);
    };

    const truncateText = (text, maxLength = 500) => {
        if (text?.length <= maxLength) {
            return text;
        }
        return text?.slice(0, maxLength) + ' ..... ';
    };

    return (
        <InfiniteScroll
            dataLength={answers.length}
            next={fetchData}
            hasMore={hasMore}
            loader={<h4>Loading...</h4>}
        >
            <div className='answer-wrapper'>
                {answers.map((answer) => (
                    <div
                        key={answer.id}
                        className="waypoint single-answer"
                        data-answer-id={answer.id}
                    // ref={ref}
                    >
                        <div>
                            <div className='answer-profile'>
                                <div>
                                    {answer.user ? (
                                        <>
                                            <div className="answer-profile-img">
                                                <img
                                                    src={apiUrlForProfilePicture + answer.user.profilePicture}
                                                    alt={answer.user.userName}
                                                    onError={(event) => {
                                                        if (!event.target.dataset.fallbackAttempted) {
                                                            event.target.dataset.fallbackAttempted = true; // Set the flag to indicate that the fallback attempt has been made
                                                            event.target.src = userIcon; // Use the regular image URL as fallback
                                                        }
                                                    }}
                                                    className='circular-image'
                                                />
                                            </div>
                                        </>
                                    ) : (
                                        <div className="answer-profile-img">
                                            <img src={userIcon} alt="Default" />
                                        </div>
                                    )}
                                </div>
                                <div>
                                    {answer.user ? (
                                        <>
                                            <p className='answer-profile-name'>{answer.user.userName}</p>
                                            <p className='answer-profile-time'>
                                                answered <Moment fromNow>{answer.createdAt}</Moment>
                                            </p>
                                        </>
                                    ) : (
                                        <>
                                            <p className='answer-profile-name'>Anonymous</p>
                                            <p className='answer-profile-time'>
                                                answered <Moment fromNow>{answer.createdAt}</Moment>
                                            </p>
                                        </>
                                    )}
                                </div>
                            </div>

                            <div>
                                <Editor
                                    editorState={EditorState.createWithContent(convertFromRaw(JSON.parse(answer.answer)))}
                                    toolbarHidden
                                    readOnly
                                    wrapperClassName='wrapper-class'
                                    editorClassName='editor-class-answer-view'
                                />
                            </div>

                            <div className='single-answer-status'>
                                <div className='single-answer-status-data'>
                                    <img src={viewIcon} alt="view" />
                                    <p>{answer.view}</p>
                                </div>
                                <div className='single-answer-status-data' onClick={() => handleClick(answer.id, 'like')}>
                                    <img src={likeIcon} alt="like" />
                                    <p>{answer.like}</p>
                                </div>
                                <div className='single-answer-status-data' onClick={() => handleClick(answer.id, 'dislike')}>
                                    <img src={disLikeIcon} alt="dislike" />
                                    <p>{answer.dislike}</p>
                                </div>


                            </div>
                        </div>
                    </div>
                ))
                }

            </div >
        </InfiniteScroll>
    )
}
export default AnswerForSelectedQuestion;  
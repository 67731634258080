import NavBar from "../components/navbar/navbar";
import RelatedQuestion from "../components/question/related-questions";
import "../assets/styles/home.css"
import Footer from "../components/footer/footer";
import SelectedQuestion from "../components/question/selected-question";
import { useParams } from "react-router-dom";
import AnswerForSelectedQuestion from "../components/answer/answer-for-selected-question";

function QuestionAnswers() {
    const { questionid } = useParams();
    console.log(questionid);
    return (
        <>
            <NavBar />
            <div className='home-wrapper'>
                <div className='col-lg-1 col-xl-1 '>
                </div>
                <div className="col-sm-12 col-md-7 col-lg-6 col-xl-6 ">
                    <div className=' '>
                        <SelectedQuestion questionid={questionid} />
                    </div>
                    <div className=' '>
                        <AnswerForSelectedQuestion questionid={questionid}  />
                    </div>

                </div>
                <div className="col-sm-12 col-md-4 col-lg-3 col-xl-3">
                    <div className=' '>
                        <RelatedQuestion questionid={questionid}/>
                    </div>
                </div>
                <div className="col-xl-2">

                </div>
            </div>
            <Footer />
        </>
    )
}

export default QuestionAnswers;
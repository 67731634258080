import NavBar from "../components/navbar/navbar";
import NotificationComponent from "../components/notification/notification-component";

function Notifications() {
    return (
        <>
            <NavBar />
            <div className='home-wrapper'>
                <div className='col-xl-1 '>
                </div>
                <div className="col-xl-6 ">
                    <div className=' '>
                        <NotificationComponent />
                    </div>
                    <div className=' '>
                    </div>
                    <div className=' '>
                    </div>

                </div>
                <div className="col-xl-3">
                    <div className=' '>

                    </div>
                </div>
                <div className="col-xl-2">

                </div>
            </div>
        </>
    )
}

export default Notifications;
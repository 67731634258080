import { useContext, useEffect, useState } from 'react';
import { EditorState, convertToRaw, convertFromRaw, Modifier } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { createAnswerApiUrl, myAnswerApiUrl, myAnswerForQuestionApiUrl, userToken } from '../../assets/api/common-data';
import axios from 'axios';
import { AnonymousUserContext } from '../../contexts/anonymous-user-context';
import acceptIcon from '../../assets/images/icons/accept.svg'
import rejectIcon from '../../assets/images/icons/reject.svg'
import deleteIcon from '../../assets/images/icons/delete.svg'
import updateIcon from '../../assets/images/icons/update.svg'
import underReviewIcon from '../../assets/images/icons/underReview.svg'
import '../../assets/styles/question-answer-form.css'
import { LanguageContext } from '../../contexts/language-context';

function AnswerForm({ questionid }) {
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
    const [answerStatus, setAnswerStatus] = useState(false);
    const [isEmpty, setIsEmpty] = useState(false);

    const [refresh, setRefresh] = useState(false);
    const [error, setError] = useState('');
    const { anonymousUser } = useContext(AnonymousUserContext);
    const { translate } = useContext(LanguageContext);

    const handleEditorChange = (newEditorState) => {
        setRefresh(false)
        setEditorState(newEditorState);
    };

    const handlePastedText = (text, html, editorState) => {     
        // Extract the font size from the HTML
        const fontSizeRegex = /font-size:\s*(\d+)px;/g;
        const fontSizeMatches = html ? [...html.matchAll(fontSizeRegex)] : [];
        const fontSize = fontSizeMatches.length > 0 ? fontSizeMatches[0][1] : null;
      
        // Apply the new inline style to the pasted content
        const contentState = editorState.getCurrentContent();
        const selectionState = editorState.getSelection();
      
        let newContentState = Modifier.replaceText(
          contentState,
          selectionState,
          text
        );
      
        if (fontSize) {
          newContentState = Modifier.applyInlineStyle(
            newContentState,
            selectionState,
            `FONT_SIZE_${fontSize}`
          );
        }
      
        // Update the editor state
        const newEditorState = EditorState.push(
          editorState,
          newContentState,
          'insert-characters'
        );
        setEditorState(newEditorState);
      
        return 'handled';
      };

    useEffect(() => {
        if (anonymousUser) {
            userAnswer();
        }
    }, [anonymousUser]);
    const userAnswer = async () => {
        try {
            const contentState = editorState.getCurrentContent();
            const contentIsEmpty = !contentState.hasText();
            if (contentIsEmpty) {
                setIsEmpty(true)
            }
            const answeContent = JSON.stringify(convertToRaw(editorState.getCurrentContent()));
            const response = await axios.post(myAnswerForQuestionApiUrl,
                { questionId: questionid, deviceId: anonymousUser },
                {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                }
            );
            setEditorState(EditorState.createWithContent(convertFromRaw(JSON.parse(response.data.updatedAnswer)))); 
            setError('')
            setAnswerStatus(response.data.isUpdatedAnswerPassed)
            setIsEmpty(false)
        } catch (error) {
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const contentState = editorState.getCurrentContent();
            const contentIsEmpty = !contentState.hasText();
            if (contentIsEmpty) {
                setIsEmpty(true)
                setError('Empty question. Please enter some content.');
                setEditorState('')
                setRefresh(false)
            } else {
                try {
                    const answeContent = JSON.stringify(convertToRaw(editorState.getCurrentContent()));
                    const response = await axios.post(createAnswerApiUrl,
                        { answer: answeContent, questionId: questionid, deviceId: anonymousUser },
                        {
                            headers: {
                                Authorization: `Bearer ${userToken}`,
                            },
                        }
                    );
                    setEditorState('')
                    setRefresh(true)
                    setError('')
                    window.location.reload();
                } catch (error) {
                    if (error.response) {
                        const { data } = error.response;
                        setError(data.message)
                    } else if (error.request) {
                        setError('Something went wrong');
                    } else {
                        setError('Something went wrong');
                    }
                }
            }
        } catch (error) {
            setError('Empty question. Please enter some content.');
        }
    }

    return (
        <>
            <form onSubmit={handleSubmit}>
                <div className='question-answer-form-wrapper'>
                    <div className='question-form-wrapper'>
                        {isEmpty &&
                            <h3>{translate("Add Answer below")}.</h3>
                        }
                        {answerStatus == null &&
                            <img src={underReviewIcon} alt='view' />
                        }
                        {answerStatus == true &&
                            <img src={acceptIcon} alt='view' />
                        }

                    </div>

                    <div>
                        <Editor
                            editorState={editorState}
                            onEditorStateChange={handleEditorChange}
                            handlePastedText={handlePastedText}
                            toolbarClassName="toolbar-class"
                            wrapperClassName="wrapper-class"
                            editorClassName="editor-class-answer" />
                    </div>
                    <div className='question-answer-form-error'>
                        <p>{error}</p>
                    </div>

                    <div className='post-question'>
                        {isEmpty &&
                            <button type='submit'>{translate("Submit")}</button>
                        }
                        {!answerStatus && !isEmpty &&
                            <button type='submit'>{translate("Update")}</button>
                        }
                        {answerStatus && !isEmpty &&
                            <button type='submit'>{translate("Update")}</button>
                        }
                    </div>
                </div>
            </form>
            {/*} <MyQuestion refresh={refresh} />*/}
        </>
    )
}
export default AnswerForm;  
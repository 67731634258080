const getAPIUrl = () => {
    if (process.env.NODE_ENV === 'development') {
        // Use localhost URL for development
        return 'http://localhost:7000';
    } else {
        // Use kirchat.com URL for production
        return 'https://core.warkaser.com';
    }
};
export const url = getAPIUrl();

export const apiUrlForImage = `${url}/product-images/`;
export const apiUrlForThumbnailsImage = `${url}/thumbnail-product-images/`;

export const apiUrlForProfilePicture = `${url}/profile-pictures/`;
export const apiUrlForLogoPicture = `${url}/logo/`;

const userProfile = JSON.parse(localStorage.getItem('userProfile')) 
export const userToken = userProfile?.accessToken

export const getCategoryApiUrl = `${url}/category/category`;

export const loginApiUrl = `${url}/user/login`;
export const signupApiUrl = `${url}/user/signup`;
export const verifyApiUrl = `${url}/user/verify`;
export const resendVerificationCodeApiUrl = `${url}/user/resendverificationcode`;
export const forgotPasswordApiUrl = `${url}/user/forgotpassword`;
export const updateForgotenPasswordApiUrl = `${url}/user/updateforgotpassword`;
export const updatePasswordApiUrl = `${url}/user/updatepassword`;

export const updateUserProfileApiUrl = `${url}/user/updateprofile`;
export const updateUserProfilePictureApiUrl = `${url}/user/updateprofilepicture`;


export const createQuestionApiUrl = `${url}/question/create`;
export const updateQuestionApiUrl = `${url}/question/update`;
export const deleteQuestionApiUrl = `${url}/question/delete`;

export const singleQuestionApiUrl = `${url}/question/singlequestion`;
export const getMyQuestionsApiUrl = `${url}/question/questions`;
export const getQuestionsForAllApiUrl = `${url}/question/questionsforall`;
export const getQuestionsForAllBySearchApiUrl = `${url}/question/questionsforallbysearch`;
export const getQuestionsByIdForAllApiUrl = `${url}/question/questionbyid`;
export const relatedQuestionsApiUrl = `${url}/question/relatedquestions`;
export const createAnswerApiUrl = `${url}/answer/create`;
export const getAnswersApiUrl = `${url}/answer/answers`;
export const myAnswersApiUrl = `${url}/answer/myanswers`;
export const myAnswerForQuestionApiUrl = `${url}/answer/myanswer`;

export const postLikeAndDislikeApiUrl = `${url}/answer/likeanddislike`;
export const viewApiUrl = `${url}/answer/view`;



import { createContext, useEffect, useState } from 'react';
import axios from 'axios';
import { getCategoryApiUrl } from '../assets/api/common-data';

export const CategoriesContext = createContext();

export const CategoriesProvider = ({ children }) => {
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState();

    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(true);
    const [refreshQuestions, setRefreshQuestions] = useState(true);
    console.log(selectedCategory);

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await axios.get(getCategoryApiUrl);
                setCategories(response.data)
                setIsLoading(false);
                localStorage.setItem('category', JSON.stringify(response.data))
            } catch (error) {
                setError(error.message)
            }
        };

        const storedCategories = localStorage.getItem('category');
        if (storedCategories) {
            setCategories(JSON.parse(storedCategories));
            setIsLoading(false);
        } else {
            fetchCategories();
        }

        //   const delay = 60 * 60 * 1000; // 1 hour in milliseconds
        const delay = 60 * 1000; // 1 minute in milliseconds
        const intervalId = setInterval(fetchCategories, delay);

        return () => {
            clearInterval(intervalId);
        };

    }, []);

    const storedCategory = localStorage.getItem('selected-category');
    useEffect(() => {
        if (!storedCategory) {
            localStorage.setItem('selected-category', 'all');
        }
        setSelectedCategory(storedCategory);
    }, [storedCategory]);

    const changeRefreshQuestions = (data) => {
        setRefreshQuestions(data);
    };

    return (
        <CategoriesContext.Provider value={{ categories, selectedCategory, isLoading, refreshQuestions, changeRefreshQuestions }}>
            {children}
        </CategoriesContext.Provider>
    );
};

import NavBar from "../components/navbar/navbar";
import RelatedQuestion from "../components/question/related-questions";
import Footer from "../components/footer/footer";
import "../assets/styles/home.css"
import QuestionForm from "../components/question/question-form";
import MyQuestion from "../components/question/my-question";

function AddQuestion() {


    return (
        <>
            <NavBar />
            <div className='home-wrapper'>
                <div className='col-xl-1 '>
                </div>
                <div className="col-sm-12 col-md-8 col-lg-7 col-xl-6 ">
                    <div className=' '>
                        <QuestionForm />
                    </div>
                </div>
                <div className="col-xl-3">
                    <div className=' '>

                    </div>
                </div>
                <div className="col-xl-2">

                </div>
            </div>
            <Footer />






        </>
    )
}

export default AddQuestion;
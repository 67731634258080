import Logo from './logo';
import Search from './search';
import User from './user';
import Language from './language';
import AddQuestionButton from './add-question-button';
import Notification from './notification';
import '../../assets/styles/main-navbar.css'

function NavBar() {
    return (
        <>
            <div className='main-navbar'>
                <div className='col-sm-0 col-md-0  col-xl-1 '>

                </div>
                <div className='col-sm-3 col-md-1  col-xl-1 '>
                    <Logo />
                </div>
                <div className='col-sm-hide col-md-5  col-xl-4'>
                    <Search />
                </div>
                <div className='col-sm-6 col-md-2 col-xl-2 '>
                    <User />
                </div>
                <div className='col-sm-3 col-md-1 col-xl-1 '>
                    <Language />
                </div>

                <div className='col-sm-hide col-md-1 col-xl-1 '>
                    <Notification />
                </div>
                <div className='col-sm-hide col-md-2 col-xl-1'>
                    <AddQuestionButton />
                </div>
                <div className='col-sm-hide col-md-0 col-xl-1'>

                </div>

            </div>




            <div className='main-navbar col-md-hide'>
                <div className='col-sm-12'>
                    <Search />
                </div>
            </div>
            <div className='main-navbar  col-md-hide'>
                <div className='sm-notification col-sm-2'>
                    <Notification />
                </div>
                <div className='col-sm-4'>
                </div>
                <div className='col-sm-6'>
                    <AddQuestionButton />
                </div>
            </div>
        </>

    )
}
export default NavBar;
import { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import '../assets/styles/login-signup.css'
import { useNavigate } from "react-router-dom";
import { UserContext } from '../contexts/user-context';
import { updateUserProfileApiUrl, userToken } from '../assets/api/common-data';
import changePassword from '../assets/images/icons/changePassword.svg';
import { LanguageContext } from '../contexts/language-context';

function UpdateProfile() {
    const { isLoggedIn, user, } = useContext(UserContext);
    const { translate } = useContext(LanguageContext);

    const [newUserName, setNewUserName] = useState('');
    const [newEmail, setNewEmail] = useState('');
    const [newGender, setNewGender] = useState('');
    const [error, setError] = useState([]);
    const navigate = useNavigate()

    useEffect(() => {
        setNewUserName(user.userName)
        setNewEmail(user.email)
        setNewGender(user.gender)
    }, [user])

    const validateUserName = (sendDataToParent2) => {
        if (newUserName?.length < 0) {
            setError(['Username should be at least 1 characters long.']);
            // return;
        }
    }
    const validateEmail = () => {
        let transformedEmail = newEmail;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (emailRegex.test(transformedEmail)) {
            setError('');
        } else {
            setError(['Please enter a valid email address.']);
            return
        }
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        if (name === 'userName') {
            setNewUserName(value);
        } else if (name === 'email') {
            setNewEmail(value);
        } else if (name === 'gender') {
            setNewGender(value);
        }
    };
    const handleGenderChange = (event) => {
        setNewGender(event.target.value);
    };
    const handleChangePassword = () => {
        navigate('/changepassword')
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        // ... validation code ...
        if (newUserName?.length < 0) {
            setError(['Username should be at least 1 characters long.']);
            return;
        }
        try {
            const response = await axios.put(updateUserProfileApiUrl,
                { userName: newUserName, email: newEmail, gender: newGender },
                {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                }
            );
            localStorage.setItem('userProfile', JSON.stringify(response.data));
            setNewUserName('');
            setNewEmail('');
            setNewGender('')
            navigate('/');
            window.location.reload();

        } catch (error) {
            if (error.response) {
                const { data } = error.response;
                setError(data.message)
            } else {
                setError(['Something went wrong']);
            }
        }
    };




    return (
        <div className="form-wrapper ">
            <form onSubmit={handleSubmit}>
                <div className='loginsignup-container '>
                    <div className='loginsignup-header'>
                        <h3>{translate("Update Profile")} </h3>
                        <div className='loginsignup-password-change' onClick={handleChangePassword}>
                            <img src={changePassword} alt="" />
                            <p>{translate("Change")}</p>
                            <p>{translate("Password")}</p>
                        </div>
                    </div>


                    <label>{translate("Username")} </label>
                    <input type="text" name="userName" value={newUserName} onChange={handleInputChange}
                        onBlur={validateUserName} placeholder={translate("Username")} />
                    <label>{translate("Email")} </label>
                    <input type="text" name="email" value={newEmail} onChange={handleInputChange}
                        onBlur={validateEmail} placeholder={translate("Email")} />

                    <label>{translate("Gender")}</label>
                    <select name="gender" value={newGender} onChange={handleGenderChange}>
                        <option value="">{translate("Select Gender")}</option>
                        <option value="MALE">{translate("MALE")}</option>
                        <option value="FEMALE">{translate("FEMALE")}</option>
                    </select>

                    {error.length > 0 &&
                        <div className='loginsignup-error'>
                            {error.map((errorData) => (
                                <p>{errorData}</p>
                            ))}
                        </div>
                    }
                    <button type='submit'>{translate("Update")}</button>
                </div>
            </form>
        </div>
    );
};

export default UpdateProfile;
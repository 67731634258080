import Footer from "../components/footer/footer";
import PrivacyPolicyComponent from "../components/footer/privacy-policy";
import NavBar from "../components/navbar/navbar";

function PrivacyPolicy() {
    return (

        <>
            <NavBar />
            <PrivacyPolicyComponent/>
            <Footer />
        </>

    )
}
export default PrivacyPolicy;  
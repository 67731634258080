import AboutWarkaComponent from "../components/footer/about-warka";
import Footer from "../components/footer/footer";
import NavBar from "../components/navbar/navbar";

function AboutWarka() {
    return (

        <>
            <NavBar />
            <AboutWarkaComponent/>
            <Footer />
        </>

    )
}
export default AboutWarka;  
import { useContext } from 'react';
import '../../assets/styles/footer.css'
import { LanguageContext } from '../../contexts/language-context';

const Footer = () => {
    const { translate } = useContext(LanguageContext);

    return (
        <footer>
            <div className="footer-wrapper">
                <div className="footer-section">
                    <h3>{translate("About Us")}</h3>
                    <ul className="social-media-list">
                        <li><a href="/aboutwarka">{translate("About Warka")} </a></li>
                        <li><a href="/termsandconditions">{translate("Terms & Conditions")} </a></li>
                        <li><a href="/privacypolicy">{translate("Privacy Policy")} </a></li>
                    </ul>
                </div>
                <div className="footer-section">
                    <h3>{translate("Contact Us")} </h3>
                    <p>{translate("Email")}: info@warkaser.com </p>
                </div>
                <div className="footer-section">
                    <h3>{translate("Follow Us")} </h3>
                    <ul className="social-media-list">
                        <li><a href="https://www.facebook.com/profile.php/?id=61557363474416">{translate("Facebook")} </a></li>
                        <li><a href="https://t.me/talk_ethio_vibes">{translate("Telegram")} </a></li>

                    </ul>
                </div>
            </div>
            <div className="footer-bottom">
                <p>&copy; 2024 warkaser.com. {translate("All rights reserved")}.</p>
            </div>
        </footer>
    );
};

export default Footer;
import React, { createContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const SearchContext = createContext();

export const SearchProvider = ({ children }) => {
  const [searchQuery, setSearchQuery] = useState();
  const [search, setSearch] = useState(false)

  const navigate = useNavigate()

  const handleSearch = () => {
    console.log('Search query:', searchQuery);
    setSearchQuery(searchQuery)
    setSearch(true)
    navigate('/search')
  };

  const contextValue = {
    searchQuery,
    search,
    setSearch,
    setSearchQuery,
    handleSearch,
  };

  return (
    <SearchContext.Provider value={contextValue}>
      {children}
    </SearchContext.Provider>
  );
};
import { createContext, useState } from 'react';

export const QuestionIdContext = createContext();

export const QuestionIdProvider = ({ children }) => {
  const [questionId, setQuestionId] = useState(null);

  return (
    <QuestionIdContext.Provider value={{ questionId, setQuestionId }}>
      {children}
    </QuestionIdContext.Provider>
  );
};
import { useContext, useState } from 'react';
import questionIcon from '../../assets/images/icons/question.svg';
import answerIcon from '../../assets/images/icons/answer.svg';
import { UserContext } from '../../contexts/user-context';
import '../../assets/styles/add-question.css'
import { useNavigate } from 'react-router-dom';
import { LanguageContext } from '../../contexts/language-context';


function AddQuestionButton() {
    const { isLoggedIn, user } = useContext(UserContext);
    const { translate } = useContext(LanguageContext);

    const navigate = useNavigate()
    const handleUserClick = () => {
        navigate('/addquestion')
    };

    return (
        <>
            <div className="add-question-wrapper" onClick={handleUserClick}>
                <div className='add-question'>
                    <div className="add-question-icon" ><img src={questionIcon} alt="" /></div>
                    <p className="add-question-name">{translate('Add Question')}</p>
                </div>
            </div>
        </>
    );
}

export default AddQuestionButton;
import React, { useState, useEffect, useContext } from 'react';
import languageIcon from '../../assets/images/icons/language.svg';
import '../../assets/styles/language.css';
import { LanguageContext } from '../../contexts/language-context';
import { useNavigate } from 'react-router-dom';

function Language() {
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const { language, translate, changeLanguage } = useContext(LanguageContext);
  const navigate = useNavigate()

  const handleLanguageChange = (newLanguage) => {
    changeLanguage(newLanguage)
    //navigate("/")
    window.location.reload()
  };

  return (
    <div className='language-wrapper'>
      <div className='language'>
        <img className='dropbtn' src={languageIcon} alt='' />
        <div className='dropdown'>
          <p className='dropbtn'>{translate(language)}</p>
          <div className='dropdown-content'>
            <a onClick={() => handleLanguageChange('amharic')}>አማርኛ</a>
            <a onClick={() => handleLanguageChange('english')}>English</a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Language;
import commentIcon from '../../assets/images/icons/comment.svg';
import { apiUrlForProfilePicture, getQuestionsForAllApiUrl, userToken } from '../../assets/api/common-data';
import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import { EditorState, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import userIcon from '../../assets/images/icons/user-icon.svg'
import viewIcon from '../../assets/images/icons/view.svg'
import likeIcon from '../../assets/images/icons/like.svg'
import disLikeIcon from '../../assets/images/icons/dislike.svg'
import '../../assets/styles/question.css';
import '../../assets/styles/answer.css'
import Moment from 'react-moment';
import InfiniteScroll from 'react-infinite-scroll-component';
import htmlReactParser from 'html-react-parser';
import { LanguageContext } from '../../contexts/language-context';
import { CategoriesContext } from '../../contexts/category-context';

function Question({ }) {
  const [questions, setQuestions] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [error, setError] = useState();
  const [limit, setLimit] = useState(2);
  const [answers, setAnswers] = useState([]);
  const [textFromRichText, setTextFromRichText] = useState({});
  const [characterCount, setCharacterCount] = useState({});
  const [expandedAnswerId, setExpandedAnswerId] = useState(null);
  const [showFullText, setShowFullText] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const { translate, language } = useContext(LanguageContext);
  const { selectedCategory, refreshQuestions, changeRefreshQuestions } = useContext(CategoriesContext);
  const [prevSelectedCategory, setPrevSelectedCategory] = useState('');
  console.log(language);
  useEffect(() => {
    let savedLanguage = localStorage.getItem('language');
    if (!savedLanguage) {
      localStorage.setItem('language', 'amharic');
    }
    let storedCategory = localStorage.getItem('selected-category');
    if (!storedCategory) {
      localStorage.setItem('selected-category', 'all');
    }
    if (refreshQuestions === true) {
      if (selectedCategory) {
        fetchData();
      }
    }
  }, [selectedCategory]);

  /*
  const fetchData = async () => {
    try {
      let fetchUrl = `${getQuestionsForAllApiUrl}?language=${language}&category=${selectedCategory}`;
      const response = await axios.get(fetchUrl);
      const uniqueQuestions = response.data.questions.filter(
        (newQuestions) => !answers.some((existingQuestion) => existingQuestion.id === newQuestions.id)
      );
      setQuestions((prevData) => [...prevData, ...uniqueQuestions]);
      setPage(response.data.currentPage + 1);
      setTotalCount(response.data.totalCount);
      setTotalPages(response.data.totalPages)
      changeRefreshQuestions(false)

      const counts = {};
      const texts = {};
      response.data.questions.forEach(question => {
        if (question.answers[0]) {
          const contentState = convertFromRaw(JSON.parse(question.answers[0].answer));
          const characterCount = contentState.getPlainText().length;
          counts[question.answers[0].id] = characterCount;
          const plainText = contentState.getPlainText();
          texts[question.answers[0].id] = plainText;
        }
      });

      setTextFromRichText((prevText) => ({ ...prevText, ...texts }));
      setCharacterCount((prevCounts) => ({ ...prevCounts, ...counts }));
      if (response.data.currentPage >= response.data.totalPages) {
        setHasMore(false);
      } else {
        setHasMore(true)
      }
    } catch (error) {
      setError('Failed to fetch items.');
    }
  };
  */

  const fetchData = async () => {
    try {
      let fetchUrl = `${getQuestionsForAllApiUrl}?language=${language}&category=${selectedCategory}`;
      const response = await axios.get(fetchUrl);
      const uniqueQuestions = response.data.questions.filter(
        (newQuestion) => !questions.some((existingQuestion) => existingQuestion.id === newQuestion.id)
      );

      // Clear the existing questions when changing the category
      if (selectedCategory !== prevSelectedCategory) {
        setQuestions([]);
      }

      setQuestions((prevQuestions) => [...prevQuestions, ...uniqueQuestions]);
      setPage(response.data.currentPage + 1);
      setTotalCount(response.data.totalCount);
      setTotalPages(response.data.totalPages);
      changeRefreshQuestions(false);

      const counts = {};
      const texts = {};
      uniqueQuestions.forEach((question) => {
        if (question.answers[0]) {
          const contentState = convertFromRaw(JSON.parse(question.answers[0].answer));
          const characterCount = contentState.getPlainText().length;
          counts[question.answers[0].id] = characterCount;
          const plainText = contentState.getPlainText();
          texts[question.answers[0].id] = plainText;
        }
      });

      setTextFromRichText((prevText) => ({ ...prevText, ...texts }));
      setCharacterCount((prevCounts) => ({ ...prevCounts, ...counts }));
      if (response.data.currentPage >= response.data.totalPages) {
        setHasMore(false);
      } else {
        setHasMore(true);
      }

      // Update the previous category value
      setPrevSelectedCategory(selectedCategory);
    } catch (error) {
      setError('Failed to fetch items.');
    }
  };

  if (questions.length === 0) {
    if (language === "amharic") {
      return <div>ለ {translate(selectedCategory)} ምድብ ምንም ጥያቄዎች አልተገኙም.</div>;
    } else {
      return <div>No questions were found for the {selectedCategory} category.</div>;
    }
  }

  return (
    <InfiniteScroll
      dataLength={questions.length}
      next={fetchData}
      hasMore={hasMore}
      loader={<h4>Loading...</h4>}
    >
      <div>
        {questions.map((question) => (
          <div key={question.id} className='question-wrapper'>
            <h1>
              <a href={`/question/${question.id}`}>
                {question.questionTitle}
              </a>
            </h1>

            {/* 
             <a href={`/question/${question.id}`}>
              <div>
                <Editor
                  editorState={EditorState.createWithContent(convertFromRaw(JSON.parse(question.question)))}
                  toolbarHidden
                  readOnly
                  wrapperClassName='wrapper-class'
                  editorClassName='editor-class-question-view'
                />
              </div>
        </a> 
        */}


            <p className='question-created-time'><Moment fromNow>{question.createdAt}</Moment>                                        </p>

            <div className='single-question-status'>
              <div className='single-question-status-data'>
                <img src={commentIcon} alt='view' />
                <p>{question.answerCount}</p>
              </div>
            </div>

            <div className='answer-wrapper'>
              {question.answers && question.answers.length > 0 ? (
                question.answers.map((answer) => (
                  answer && answer.answer ? (
                    <div className='single-answer' key={answer.id}>
                      {answer.user && answer.user.userName ? (
                        <div className='answer-profile'>
                          <div className='answer-profile-img'>
                            <img
                              src={apiUrlForProfilePicture + answer.user.profilePicture}
                              alt={answer.user.userName}
                              onError={(event) => {
                                if (!event.target.dataset.fallbackAttempted) {
                                  event.target.dataset.fallbackAttempted = true; // Set the flag to indicate that the fallback attempt has been made
                                  event.target.src = userIcon; // Use the regular image URL as fallback
                                }
                              }}
                              className='circular-image'

                            />
                          </div>
                          <div>
                            <p className='answer-profile-name'>{answer.user.userName}</p>
                            <p className='answer-profile-time'>answered <Moment fromNow>{answer.createdAt}</Moment>                                        </p>
                          </div>
                        </div>
                      ) : (
                        <div className='answer-profile'>
                          <div className='answer-profile-img'>
                            <img src={userIcon} alt="" />
                          </div>
                          <div>
                            <p className='answer-profile-name'>{translate("anonymous")}</p>
                            <p className='answer-profile-time'>answered <Moment fromNow>{answer.createdAt}</Moment>                                        </p>
                          </div>
                        </div>
                      )}
                      <div>
                        <h2>
                          <Editor
                            editorState={EditorState.createWithContent(convertFromRaw(JSON.parse(answer.answer)))}
                            toolbarHidden
                            readOnly
                            wrapperClassName='wrapper-class'
                            editorClassName='editor-class-answer-view'
                          />
                        </h2>
                      </div>

                      <div className='single-answer-status'>
                        <div className='single-answer-status-data'>
                          <img src={viewIcon} alt="view" />
                          <p>{answer.view}</p>
                        </div>
                        <div className='single-answer-status-data'>
                          <img src={likeIcon} alt="like" />
                          <p>{answer.like}</p>
                        </div>
                        <div className='single-answer-status-data'>
                          <img src={disLikeIcon} alt="dislike" />
                          <p>{answer.dislike}</p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div>{translate("No answer found for this question")}.</div>
                  )
                ))
              ) : (
                <div>{translate("No answer found for this question")}.</div>
              )}
            </div>
          </div>
        ))}
      </div>
    </InfiniteScroll>
  );
}

export default Question;
import { useContext, useEffect, useState } from 'react';
import '../../assets/styles/related-questions.css'
import { LanguageContext } from '../../contexts/language-context';
import axios from 'axios';
import { relatedQuestionsApiUrl } from '../../assets/api/common-data';
import { convertFromRaw } from 'draft-js';


function RelatedQuestion({ questionid }) {
    const { translate, changeLanguage } = useContext(LanguageContext);
    const [relatedQuestions, setRelatedQuestions] = useState([]);
    const [error, setError] = useState(null);
    const [characterCounts, setCharacterCounts] = useState({});
    const [texts, setTexts] = useState({});
    const [expandedId, setExpandedId] = useState(null);
    const [showFullText, setShowFullText] = useState(false);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const fetchUrl = `${relatedQuestionsApiUrl}/${questionid}`;
            const response = await axios.get(fetchUrl);
            setRelatedQuestions(response.data.relatedQuestions);
            setError('');

            const counts = {};
            const texts = {};

            response.data.relatedQuestions.forEach(relatedQuestion => {
                const contentState = convertFromRaw(JSON.parse(relatedQuestion.question));
                const characterCount = contentState.getPlainText().length;
                counts[relatedQuestion.id] = characterCount;
                const plainText = contentState.getPlainText();
                texts[relatedQuestion.id] = plainText;
            });

            setCharacterCounts(counts);
            setTexts(texts);

        } catch (error) {
            setError(error.message);
        }
    };

    const truncateText = (text, maxLength = 100) => {
        if (text?.length <= maxLength) {
            return text;
        }
        return text?.slice(0, maxLength) + ' ..... ';
    };

    const handleExpand = (answerId) => {
        setExpandedId(answerId);
    };

    return (
        <>
            {relatedQuestions.length > 0 &&
                <div className="related-questions-wrapper">
                    <h3>{translate("related questions")}</h3>
                    <hr></hr>
                    {relatedQuestions.map((relatedQuestion) => (
                        <div key={relatedQuestion.id}>
                            <div className='related-question-see-more'>
                                <a href={`/question/${relatedQuestion.id}`}>

                                    <p>
                                        {expandedId !== relatedQuestion.id && (
                                            <>
                                                {showFullText || characterCounts[relatedQuestion.id] < 100
                                                    ? texts[relatedQuestion.id]
                                                    : truncateText(texts[relatedQuestion.id])}
                                                {/*{characterCounts[relatedQuestion.id] > 10 && (
                      <a onClick={() => handleExpand(relatedQuestion.id)}>See more</a>
                    )}*/}
                                            </>
                                        )}
                                    </p>
                                </a>
                            </div>
                            {expandedId === relatedQuestion.id && (
                                <p>{texts[relatedQuestion.id]}</p>
                            )}
                        </div>
                    ))}
                </div>
            }
        </>
    );
}

export default RelatedQuestion;
import NavBar from "../components/navbar/navbar";
import RelatedQuestion from "../components/question/related-questions";
import Footer from "../components/footer/footer";
import "../assets/styles/home.css"
import MyQuestion from "../components/question/my-question";
import AnswerForm from "../components/answer/answer-form";
import Question from "../components/question/questions";
import SelectedQuestion from "../components/question/selected-question";
import { useParams } from "react-router-dom";

function AddAnswer() {
    const { questionid } = useParams();

    return (
        <>
            <NavBar />
            <div className='home-wrapper'>
                <div className='col-xl-1 '>
                </div>
                <div className="col-sm-12 col-md-8 col-lg-7 col-xl-6 ">
                    <div className=' '>
                        <SelectedQuestion questionid={questionid} />
                    </div>
                    <div className=' '>
                        <AnswerForm questionid={questionid} />
                    </div>
                    <div className=' '>
                    </div>
                    <div className=' '>
                        
                    </div>

                </div>
                <div className="col-xl-3">
                    <div className=' '>
                        
                    </div>
                </div>
                <div className="col-xl-2">

                </div>
            </div>

            <Footer />
        </>
    )
}

export default AddAnswer;